import { Errors } from '../objects/errors';

export const TOGGLE_SHOW_PASSWORD = "RESET_PASSWORD_TOGGLE_PASSWORD";
export const SET_ERRORS = 'RESET_PASSWORD_SET_ERRORS';
export const UPDATE_CONFIRM_NEW_PASSWORD = 'RESET_PASSWORD_UPDATE_CONFIRM_NEW_PASSWORD';
export const UPDATE_NEW_PASSWORD = 'RESET_PASSWORD_UPDATE_NEW_PASSWORD';

interface SetErrorsAction {
    type: typeof SET_ERRORS,
    payload: Errors
}

interface ToggleShowPassword {
    type: typeof TOGGLE_SHOW_PASSWORD,
    payload: null
}

interface UpdateConfirmNewPasswordAction {
    type: typeof UPDATE_CONFIRM_NEW_PASSWORD,
    payload: string
}

interface UpdateNewPasswordAction {
    type: typeof UPDATE_NEW_PASSWORD,
    payload: string
}

export type ResetPasswordActionTypes = ToggleShowPassword | SetErrorsAction | UpdateConfirmNewPasswordAction | UpdateNewPasswordAction;