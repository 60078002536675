export const SET_AGENT_REGISTERING_CUSTOMER = "SET_AGENT_REGISTERING_CUSTOMER";
export const SET_CREATE_NEW_PASSWORD = "CREATE_NEW_PASSWORD";
export const SET_REGISTER_CONFIRM_PASSWORD = "SET_REGISTER_CONFIRM_PASSWORD";
export const SET_REGISTER_EMAIL = "SET_REGISTER_EMAIL";
export const SET_REGISTER_FIRST_NAME = "SET_REGISTER_FIRST_NAME";
export const SET_REGISTER_LAST_NAME = "SET_REGISTER_LAST_NAME";
export const SET_REGISTER_PASSWORD = "SET_REGISTER_PASSWORD";
export const SET_OPEN_CONFIRM_EMAIL = "SET_OPEN_CONFIRM_EMAIL";
export const TOGGLE_REGISTER_SHOW_PASSWORD = "TOGGLE_REGISTER_SHOW_PASSWORD";

interface SetAgentRegisteringCustomer {
    type: typeof SET_AGENT_REGISTERING_CUSTOMER,
    payload: boolean
}

interface SetCreateNewPassword {
    type: typeof SET_CREATE_NEW_PASSWORD,
    payload: boolean
}

interface SetRegisterConfirmPassword {
    type: typeof SET_REGISTER_CONFIRM_PASSWORD,
    payload: string
}

interface SetRegisterEmail {
    type: typeof SET_REGISTER_EMAIL,
    payload: string
}

interface SetRegisterFirstName {
    type: typeof SET_REGISTER_FIRST_NAME,
    payload: string
}

interface SetRegisterLastName{
    type: typeof SET_REGISTER_LAST_NAME,
    payload: string
}

interface SetRegisterPassword {
    type: typeof SET_REGISTER_PASSWORD,
    payload: string
}

interface SetOpenConfirmEmail {
    type: typeof SET_OPEN_CONFIRM_EMAIL,
    payload: boolean
}

interface ToggleRegisterShowPassword {
    type: typeof TOGGLE_REGISTER_SHOW_PASSWORD,
    payload: null
}

export type UserActionTypes = SetAgentRegisteringCustomer | SetCreateNewPassword | SetRegisterConfirmPassword | SetRegisterEmail | SetRegisterFirstName | SetRegisterLastName
                            | SetRegisterPassword | SetOpenConfirmEmail | ToggleRegisterShowPassword