import { Traveler } from "../objects/traveler";

export const SET_CHECKS_PASSED = "SET_CHECKS_PASSED";
export const SET_SAME_TRAVELER = "SET_SAME_TRAVELER";

interface SetChecksPassed {
    type: typeof SET_CHECKS_PASSED,
    payload: boolean | null
}

interface SetSameTraveler {
    type: typeof SET_SAME_TRAVELER,
    payload: Array<Traveler>
}

export type TravelerActionTypes = SetChecksPassed | SetSameTraveler