import { HeaderState } from '../objects/headerState';
import { SET_CONFIG, HeaderActionTypes } from './actionTypes';

const initialState: HeaderState = {
    client_id: null,
    favicon: null,
    language: "fr",
    logo: null,
    retrieved: false,
};

export default (state = initialState, action: HeaderActionTypes): HeaderState => {
    switch (action.type) {
        case SET_CONFIG: {
            const { client_id, logo, favicon } = action.payload;
            return {
                ...state,
                client_id: client_id,
                logo: logo,
                favicon: favicon,
                retrieved: true,
            };
        }
        default:
            return state;
    }
};