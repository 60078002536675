import FormatErrors from "../functions/FormatErrors"
import { ResetPasswordState } from '../objects/resetPasswordState';
import { ResetPasswordActionTypes, SET_ERRORS, TOGGLE_SHOW_PASSWORD, UPDATE_CONFIRM_NEW_PASSWORD, UPDATE_NEW_PASSWORD } from './actionTypes';

const initialState: ResetPasswordState = {
    confirm_new_password: '',
    confirm_new_password_error: '',
    new_password: '',
    new_password_error: '',
    show_password: false
};

export default (state = initialState, action: ResetPasswordActionTypes): ResetPasswordState => {
    switch (action.type) {
        case TOGGLE_SHOW_PASSWORD: {
            return {
                ...state,
                show_password: !state.show_password
            }
        }
        case SET_ERRORS: {
            const { password, confirm_password } = action.payload;
            return {
                ...state,
                confirm_new_password_error: confirm_password ? Array.isArray(confirm_password) ? FormatErrors(confirm_password) : confirm_password : '',
                new_password_error: password ? Array.isArray(password) ? FormatErrors(password) : password : ''
            };
        }
        case UPDATE_CONFIRM_NEW_PASSWORD: {
            return {...state, confirm_new_password: action.payload};
        }
        case UPDATE_NEW_PASSWORD: {
            return {...state, new_password: action.payload};
        }
        default:
            return state;
    }
};