import { Country } from "../../iata/objects/country";
import { LoyaltyCard } from "./loyaltyCard";

export enum Gender {
    Male = "M",
    Female = "F",
    Other ="O"
}

export enum Title {
    MR = "MR",
    MISS = "MISS",
    MRS = "MRS",
    MX = "MX"
}

export enum TripReason {
    Vacation = "VACATION",
    Culture = "CULTURE",
    Sport = "SPORT",
    Business = "BUSINESS"
}

export enum Relationship {
    Family = "FAMILY",
    Friend = "FRIEND",
    Couple = "COUPLE",
    Alone = "ALONE"
}

export enum IdentityDocument {
    Card = "CARD",
    Passport = "PASSPORT"
}

export type Traveler = {
    address: null | string,
    address_details: null | string,
    age_label?: null | string,
    birth_city: string,
    birth_country: null | Country,
    birth_date: null | string,
    birth_zip_code: string,
    city: null | string,
    country: null | Country | "",
    default_name?: boolean,
    email: null | string,
    external_code: null | string,
    first_name: null | string,
    flight_type?: string,
    gender: Gender,
    id: number,
    identity_doc: IdentityDocument,
    identity_doc_country_emission: null | Country | "",
    identity_doc_expiration: null | string,
    identity_doc_number: null | string,
    last_name: null | string,
    loyalty_cards: Array<LoyaltyCard>
    middle_name: null | string,
    nationality: null | Country | "",
    owner: number,
    phone_country_code: null | string,
    phone_number: null | string,
    postal_code: null | string,
    temp_phone?: string,
    title: Title,
    traveler_relationship: Relationship,
    trip_reason: TripReason,
    weight: null | number
}

export type TravelerInsurance = {
    currency: string,
    trip_price_cents?: number,
    pax_type: string,
    price_cents: number,
    traveler_id: number,
}