import { Flight} from '../../flight/objects/flight';
import { FlightProduct } from '../../flight/objects/flightProduct';
import { ReservationMode } from '../../flight/objects/reservationMode';
import { Traveler } from '../../travelers/objects/traveler';
import { TravelerGroup } from '../../travelers/objects/travelerGroup';
import { User } from '../../user/objects/user';
import { Agency } from '../objects/agency';
import { ListDateType } from '../objects/dateType';
import { Document } from '../objects/document';
import { InsuranceProduct } from '../objects/insuranceProduct';
import { RepriceData } from '../objects/repriceData';
import { Trip } from '../objects/trip';
import { TripResults } from "../objects/tripResults";
import { TripVersion } from '../objects/tripVersion';

import {
    TripActionTypes,
    CHOOSE_MODE_AFTER_OPTION,
    ADD_FILE_TO_DL,
    CHANGE_GROUP_TO_REPLACE,
    CLEAR_FILES_TO_DL,
    RESET_FILTERS,
    RESET_TRIP,
    SET_AGENCIES,
    SET_AGENCY_FILTER,
    SET_AGENCY_USER_FILTER,
    SET_AGENCY_USERS,
    SET_CREATOR_FILTER,
    SET_CREATORS,
    SET_DATE_TYPE,
    SET_DEPOSIT_AMOUNT,
    SET_FILTERED_DATE,
    SET_FILTERED_END_DATE,
    SET_FILTERED_START_DATE,
    SET_FLIGHT,
    SET_FLIGHT_GROUP_PASSENGER,
    SET_FLIGHT_TRANSLATED,
    SET_GROUP_TO_REPLACE,
    SET_INSURANCES,
    SET_LAUNCH_EDIT,
    SET_LAUNCH_OPTION,
    SET_LIMIT,
    SET_MARGIN_REDUCTION_PERCENTAGE,
    SET_MIN_DEPOSIT,
    SET_NEW_FARES,
    SET_OPEN_REPRICE_RESULT,
    SET_ORDER,
    SET_ORDER_BY,
    SET_PAGE,
    SET_PAX_NUMBER,
    SET_RELOAD_LIST,
    SET_REPRICE_DATA,
    SET_SEARCH,
    SET_TAB_NB,
    SET_TOTAL_PAID,
    SET_TRAVELER_TO_REMOVE,
    SET_TRIP_DOCS,
    SET_TRIP_DOCS_DOC,
    SET_TRIP_EXTERNAL_CODE,
    SET_TRIP_INFO,
    SET_TRIP_INFO_VERSION,
    SET_TRIP_LIST,
    SET_TRIP_LIST_FILTER,
    SET_TRIP_STATUS_FILTER,
    SET_TRIP_TOKEN,
    SET_USE_FARE_BASIS,
    TOGGLE_OPEN_CONFIRM_CANCEL,
    TOGGLE_OPEN_FLIGHT_DETAILS,
    TOGGLE_OPEN_REMOVE_TRAVELER,
    UPDATE_END_DATE,
    UPDATE_GROUPS,
    UPDATE_PGI,
    UPDATE_START_DATE,
    UPDATE_TRAVELERS,
} from './actionTypes';

export const addFileToDl = (file_name: string, blob: Blob): TripActionTypes => ({
    type: ADD_FILE_TO_DL,
    payload: {
        file_name: file_name,
        blob: blob
    }
})

export const changeGroupToReplace = (group_index: number): TripActionTypes => ({
    type: CHANGE_GROUP_TO_REPLACE,
    payload: group_index
})

export const chooseModeAfterOption = (mode: ReservationMode): TripActionTypes => ({
    type: CHOOSE_MODE_AFTER_OPTION,
    payload: mode
})

export const clearFilesToDl = (): TripActionTypes => ({
    type: CLEAR_FILES_TO_DL,
    payload: null
})

export const resetFilters = (): TripActionTypes => ({
    type: RESET_FILTERS,
    payload: null
})

export const resetTrip = (): TripActionTypes => ({
    type: RESET_TRIP,
    payload: null
});

export const setAgencies = (agencies: Array<Agency>): TripActionTypes => ({
    type: SET_AGENCIES,
    payload: agencies
})

export const setAgencyFilter = (agency: Agency | null): TripActionTypes => ({
    type: SET_AGENCY_FILTER,
    payload: agency
})

export const setAgencyUserFilter = (users: Array<User>): TripActionTypes => ({
    type: SET_AGENCY_USER_FILTER,
    payload: users
})

export const setAgencyUsers = (users: Array<User>): TripActionTypes => ({
    type: SET_AGENCY_USERS,
    payload: users
})

export const setCreatorFilter = (creators: Array<User>): TripActionTypes => ({
    type: SET_CREATOR_FILTER,
    payload: creators
})

export const setCreators = (creators: Array<User>): TripActionTypes => ({
    type: SET_CREATORS,
    payload: creators
})

export const setDateType = (date_type: ListDateType): TripActionTypes => ({
    type: SET_DATE_TYPE,
    payload: date_type
})

export const setDepositAmount = (amount: number): TripActionTypes => ({
    type: SET_DEPOSIT_AMOUNT,
    payload: amount
})

export const setFilteredDate = (filtered_date: string): TripActionTypes => ({
    type: SET_FILTERED_DATE,
    payload: filtered_date
})

export const setFilteredEndDate = (end_date: string | null): TripActionTypes => ({
    type: SET_FILTERED_END_DATE,
    payload: end_date
})


export const setFilteredStartDate = (start_date: string | null): TripActionTypes => ({
    type: SET_FILTERED_START_DATE,
    payload: start_date
})

export const setLaunchEdit = (launch: boolean): TripActionTypes => ({
    type: SET_LAUNCH_EDIT,
    payload: launch
})

export const setLaunchOption = (launch: boolean): TripActionTypes => ({
    type: SET_LAUNCH_OPTION,
    payload: launch
})

export const setLimit = (limit: number): TripActionTypes => ({
    type: SET_LIMIT,
    payload: limit
});

export const setMarginReductionPercentage = (margin_reduction: number): TripActionTypes => ({
    type: SET_MARGIN_REDUCTION_PERCENTAGE,
    payload: margin_reduction
})

export const setMinDeposit = (minimum: number): TripActionTypes => ({
    type: SET_MIN_DEPOSIT,
    payload: minimum
})

export const setFlight =(flight: FlightProduct | null): TripActionTypes => ({
    type: SET_FLIGHT,
    payload: flight
})

export const setFlightGroupPassenger = (group_passenger: TravelerGroup): TripActionTypes => ({
    type: SET_FLIGHT_GROUP_PASSENGER,
    payload: group_passenger
})

export const setFlightTranslated = (flight: Flight | null): TripActionTypes => ({
    type: SET_FLIGHT_TRANSLATED,
    payload: flight
})

export const setGroupToReplace = (groups: Array<number>): TripActionTypes => ({
    type: SET_GROUP_TO_REPLACE,
    payload: groups
})

export const setInsurance = (insurances: Array<InsuranceProduct>): TripActionTypes => ({
    type: SET_INSURANCES,
    payload: insurances
})

export const setTabNb = (tab_nb: number): TripActionTypes => ({
    type: SET_TAB_NB,
    payload: tab_nb
})

export const setTotalPaid = (total: number): TripActionTypes => ({
    type: SET_TOTAL_PAID,
    payload: total
})

export const setTravelerToRemove = (traveler: Traveler | null): TripActionTypes => ({
    type: SET_TRAVELER_TO_REMOVE,
    payload: traveler
})

export const setOpenRepriceResult = (open: boolean): TripActionTypes => ({
    type: SET_OPEN_REPRICE_RESULT,
    payload: open
})

export const setOrder = (order: string): TripActionTypes => ({
    type: SET_ORDER,
    payload: order
});

export const setOrderBy = (order_by: string): TripActionTypes => ({
    type: SET_ORDER_BY,
    payload: order_by
});

export const setPage = (page: number): TripActionTypes => ({
    type: SET_PAGE,
    payload: page
});

export const setPaxNumber = (pax_nb: Array<{label: string}>): TripActionTypes => ({
    type: SET_PAX_NUMBER,
    payload: pax_nb
})

export const setReloadList = (reload_list: boolean): TripActionTypes => ({
    type: SET_RELOAD_LIST,
    payload: reload_list
})

export const setRepriceData = (reprice_data: RepriceData | null): TripActionTypes => ({
    type: SET_REPRICE_DATA,
    payload: reprice_data
})

export const setSearch = (search: string): TripActionTypes => ({
    type: SET_SEARCH,
    payload: search
});

export const setTripDocs = (trip_docs: Array<Document>): TripActionTypes => ({
    type: SET_TRIP_DOCS,
    payload: trip_docs
})

export const setTripDocsDoc = (trip_document: Document): TripActionTypes => ({
    type: SET_TRIP_DOCS_DOC,
    payload: trip_document
})

export const setTripExternalCode = (code: string): TripActionTypes => ({
    type: SET_TRIP_EXTERNAL_CODE,
    payload: code
})

export const setTripInfo = (trip_info: Trip | null): TripActionTypes => ({
    type: SET_TRIP_INFO,
    payload: trip_info
});

export const setTripInfoVersion = (version: TripVersion): TripActionTypes => ({
    type: SET_TRIP_INFO_VERSION,
    payload: version
});

export const setTripList = (tripResults: TripResults | null): TripActionTypes => ({
    type: SET_TRIP_LIST,
    payload: tripResults
});

export const setTripListFilter = (filter: string): TripActionTypes => ({
    type: SET_TRIP_LIST_FILTER,
    payload: filter
})

export const setTripListStatusFilter = (status_filter: Array<{label: string}>): TripActionTypes => ({
    type: SET_TRIP_STATUS_FILTER,
    payload: status_filter
})

export const setTripToken = (token: string) : TripActionTypes => ({
    type: SET_TRIP_TOKEN,
    payload: token
})

export const setUseFareBasis = (use_fare_basis: boolean): TripActionTypes => ({
    type: SET_USE_FARE_BASIS,
    payload: use_fare_basis
})

export const toggleOpenConfirmCancel = (open: boolean): TripActionTypes => ({
    type: TOGGLE_OPEN_CONFIRM_CANCEL,
    payload: open
})

export const toggleOpenFlightDetails = (): TripActionTypes => ({
    type: TOGGLE_OPEN_FLIGHT_DETAILS,
    payload: null
})

export const toggleOpenRemoveTraveler = (): TripActionTypes => ({
    type: TOGGLE_OPEN_REMOVE_TRAVELER,
    payload: null
})

export const updateEndDate = (end_date: string | null): TripActionTypes => ({
    type: UPDATE_END_DATE,
    payload: end_date
});

export const updateGroups = (groups: Array<any>): TripActionTypes => ({
    type: UPDATE_GROUPS,
    payload: groups
});

export const updatePgi = (pgi: string): TripActionTypes => ({
    type: UPDATE_PGI,
    payload: pgi
});

export const updateStartDate = (start_date: string | null): TripActionTypes => ({
    type: UPDATE_START_DATE,
    payload: start_date
});

export const updateTravelers = (travelers: Array<Traveler>): TripActionTypes => ({
    type: UPDATE_TRAVELERS,
    payload: travelers
});
