import { LoadingState } from '../objects/loadingState';
import { LoadingActionTypes, SET_LOADING } from './actionTypes';

const initialState: LoadingState = {
    loading: false
};

export default (state: LoadingState = initialState, action: LoadingActionTypes): LoadingState => {
    switch (action.type) {
        case SET_LOADING: {
            return {...state, loading: action.payload};
        }
        default:
            return state;
    }
};