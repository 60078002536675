import { UserState } from "../objects/userState";

import { UserActionTypes, SET_AGENT_REGISTERING_CUSTOMER, SET_CREATE_NEW_PASSWORD, SET_REGISTER_CONFIRM_PASSWORD, SET_REGISTER_EMAIL, SET_REGISTER_FIRST_NAME, SET_REGISTER_LAST_NAME,
         SET_REGISTER_PASSWORD, SET_OPEN_CONFIRM_EMAIL, TOGGLE_REGISTER_SHOW_PASSWORD } from "./actionTypes";

const initialState: UserState = {
    agent_registering_customer: false,
    create_new_password: false,
    register_confirm_password: "",
    register_email: "",
    register_first_name: "",
    register_last_name: "",
    register_password: "",
    register_show_password: false,
    open_confirm_email: false
}

export default (state = initialState, action: UserActionTypes): UserState => {
    switch(action.type){
        case SET_AGENT_REGISTERING_CUSTOMER: {
            return {
                ...state,
                agent_registering_customer: action.payload
            }
        }
        case SET_CREATE_NEW_PASSWORD: {
            return {
                ...state,
                create_new_password: action.payload
            }
        }
        case SET_REGISTER_CONFIRM_PASSWORD: {
            return {
                ...state,
                register_confirm_password: action.payload
            }
        }
        case SET_REGISTER_EMAIL: {
            return {
                ...state,
               register_email: action.payload
            }
        }
        case SET_REGISTER_FIRST_NAME: {
            return {
                ...state,
                register_first_name: action.payload
            }
        }
        case SET_REGISTER_LAST_NAME: {
            return {
                ...state,
                register_last_name: action.payload
            }
        }
        case SET_REGISTER_PASSWORD: {
            return {
                ...state,
                register_password: action.payload
            }
        }
        case SET_OPEN_CONFIRM_EMAIL: {
            const open_dialog = action.payload;
            return {
                ...state,
                open_confirm_email: open_dialog
            }
        }
        case TOGGLE_REGISTER_SHOW_PASSWORD: {
            return {
                ...state,
                register_show_password: !state.register_show_password
            }
        }
        default:
            return state
    }
}