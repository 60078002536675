import { User } from '../../user/objects/user';

export const CHECK_EMAIL_DONE = "CHECK_EMAIL_DONE"; 
export const LOAD_PROFILE_STATE = "LOAD_PROFILE_STATE";
export const SET_CUSTOMER_PROFILE = "SET_CUSTOMER_PROFILE";
export const SET_PROFILE = 'SET_PROFILE';
export const SET_LOGIN = 'SET_LOGIN';

interface CheckEmailDone {
    type: typeof CHECK_EMAIL_DONE,
    payload: null
}

interface LoadProfileState {
    type: typeof LOAD_PROFILE_STATE,
    payload: {
        customer_profile: User
    }
}

interface SetCustomerProfile {
    type: typeof SET_CUSTOMER_PROFILE,
    payload: null | User
}

interface SetProfileAction {
    type: typeof SET_PROFILE,
    payload: null | User
}

interface SetLoginAction {
    type: typeof SET_LOGIN,
    payload: {
        token: string,
        user: User | null,
        permissions: []
    }
}

export type ProfileActionTypes = CheckEmailDone | LoadProfileState | SetCustomerProfile | SetProfileAction | SetLoginAction;