declare var API_HREF: string;

// Dependencies
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import React, { FC, ReactElement, ComponentType } from 'react';

// Components
import Loading from '../component/loading/loading';

// Objects
import { AppState } from '../reducers';

// Actions
import { setConfig } from '../component/header/redux/actions';


type PublicRouteProps = {
    path: string,
    component: ComponentType
};

const PublicRoute: FC<PublicRouteProps> = ({ component: Component, ...rest }): ReactElement => {
    const dispatch = useDispatch();
    const client_id = useSelector((state: AppState) => state.header.client_id);
    if (client_id === null) {
        let customer_identifier = /.([^.]+)/.exec(window.location.host)[0];
        let domain_name = window.location.host;
        if (window.location.host === 'localhost:3002') {
            // customer_identifier = 'dev';
            // domain_name = 'dev.facilitatrip.com';
            customer_identifier = 'vat-dev';
            domain_name = 'vat-dev.facilitatrip.com';
            // customer_identifier = 'vat';
            // domain_name = 'vat.facilitatrip.com';
        }
        axios({
            method: 'GET',
            url: `${API_HREF}instances/?customer_identifier=${customer_identifier}&domain_name=${domain_name}`
        }).then((response) => {
            dispatch(setConfig(response.data));
        }).catch((error) => {
            console.log('error : ', error);
        });
        return <Loading/>;
    }
    return <Component {...rest}/>;
};

export default PublicRoute;