import { LoginState } from '../objects/loginState';
import { UPDATE_EMAIL, UPDATE_PASSWORD, TOGGLE_FORGOTTEN_PASSWORD, TOGGLE_OPEN_CONFIRMATION, TOGGLE_PASSWORD, SET_ERRORS, LoginActionTypes } from './actionTypes';

const initialState: LoginState = {
    email: '',
    forgotten_password: false,
    open_confirmation: false,
    password: '',
    show_password: false,
    errors: {
        email: false,
        password: false
    }
};

export default (state = initialState, action: LoginActionTypes): LoginState => {
    switch (action.type) {
        case UPDATE_EMAIL: {
            return {...state, email: action.payload};
        }
        case UPDATE_PASSWORD: {
            return {...state, password: action.payload};
        }
        case TOGGLE_FORGOTTEN_PASSWORD: {
            return { ...state, forgotten_password: !state.forgotten_password }
        }
        case TOGGLE_OPEN_CONFIRMATION: {
            return { ...state, open_confirmation: !state.open_confirmation }
        }
        case TOGGLE_PASSWORD: {
            return {...state, show_password: !state.show_password};
        }
        case SET_ERRORS: {
            return {...state, errors: {email: action.payload.email, password: action.payload.password}};
        }
        default:
            return state;
    }
};