import { Gender, IdentityDocument, Relationship, Title, Traveler, TripReason } from "../objects/traveler";
import { TravelerState } from "../objects/travelerState";

import { TravelerActionTypes, SET_CHECKS_PASSED, SET_SAME_TRAVELER } from "./actionTypes";

const initialState: TravelerState = {
    checks_passed: null,
    same_traveler: []
}

export const DefaultTraveler: Traveler = {
    first_name: "",
    last_name: "",
    email: "",
    birth_date: "1990-01-01",
    phone_country_code: null,
    phone_number: null,
    id: 0,
    gender: Gender.Male,
    address: "",
    address_details: "",
    postal_code: "",
    city: "",
    country: "",
    middle_name: null,
    nationality: "",
    identity_doc: IdentityDocument.Card,
    identity_doc_country_emission: "",
    identity_doc_expiration: null,
    identity_doc_number: "",
    loyalty_cards: [],
    owner: 1,
    temp_phone: "",
    title: Title.MR,
    traveler_relationship: Relationship.Family,
    trip_reason: TripReason.Vacation,
    weight: null
}

export default (state = initialState, action: TravelerActionTypes): TravelerState => {
    switch(action.type){
        case SET_CHECKS_PASSED: {
            const checks_passed = action.payload;
            return {
                ...state,
                checks_passed: checks_passed
            }
        }
        case SET_SAME_TRAVELER: {
            const same_traveler = action.payload;
            return {
                ...state,
                same_traveler: same_traveler
            }
        }
        default:
            return state;
    }
}