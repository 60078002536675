import { ProfileState } from '../objects/profileState';
import { CHECK_EMAIL_DONE, LOAD_PROFILE_STATE, SET_CUSTOMER_PROFILE, SET_PROFILE, SET_LOGIN, ProfileActionTypes } from './actionTypes';

const initialState: ProfileState = {
    customer_profile: null,
    retrieved: false,
    id: localStorage.getItem('user_id') !== null ? parseInt(<string>localStorage.getItem('user_id')) : null,
    token: localStorage.getItem('token'),
    profile: null,
    permissions: []
};

export default (state = initialState, action: ProfileActionTypes): ProfileState => {
    switch (action.type) {
        case CHECK_EMAIL_DONE: {
            if (state.profile === null){
                return state
            }else{
                let temp_profile = Object.assign({}, state.profile);
                temp_profile.email_checked = true;
                return {
                    ...state,
                    profile: temp_profile
                }
            }
        }
        case LOAD_PROFILE_STATE: {
            const { customer_profile } = action.payload;
            return {
                ...state,
                customer_profile: customer_profile
            }
        }
        case SET_CUSTOMER_PROFILE: {
            return {
                ...state,
                customer_profile: action.payload
            }
        }
        case SET_PROFILE: {
            return {...state, retrieved: true, profile: action.payload};
        }
        case SET_LOGIN: {
            const { token, user, permissions } = action.payload;
            localStorage.setItem("token", token);
            localStorage.setItem("user_id", user !== null ? user.id.toString() : "");
            if (user !== null && !user.is_shadow){
                localStorage.setItem("user_name", user.first_name + " " + user.last_name);
            }
            return {
                ...state,
                retrieved: true,
                id: user !== null ? user.id : null,
                profile: user,
                token: token,
                permissions: permissions
            }
        }
        default:
            return state;
    }
};