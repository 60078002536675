import { TripState } from '../objects/tripState';
import { TripVersion } from '../objects/tripVersion';

import moment from "moment"

import {
    TripActionTypes,
    ADD_FILE_TO_DL,
    CHANGE_GROUP_TO_REPLACE,
    CHOOSE_MODE_AFTER_OPTION,
    CLEAR_FILES_TO_DL,
    RESET_FILTERS,
    RESET_TRIP,
    SET_AGENCIES,
    SET_AGENCY_FILTER,
    SET_AGENCY_USER_FILTER,
    SET_AGENCY_USERS,
    SET_CREATOR_FILTER,
    SET_CREATORS,
    SET_DATE_TYPE,
    SET_DEPOSIT_AMOUNT,
    SET_FILTERED_DATE,
    SET_FILTERED_END_DATE,
    SET_FILTERED_START_DATE,
    SET_FLIGHT,
    SET_FLIGHT_GROUP_PASSENGER,
    SET_FLIGHT_TRANSLATED,
    SET_GROUP_TO_REPLACE,
    SET_INSURANCES,
    SET_LAUNCH_EDIT,
    SET_LAUNCH_OPTION,
    SET_LIMIT,
    SET_MARGIN_REDUCTION_PERCENTAGE,
    SET_MIN_DEPOSIT,
    SET_OPEN_REPRICE_RESULT,
    SET_ORDER,
    SET_ORDER_BY,
    SET_PAGE,
    SET_PAX_NUMBER,
    SET_RELOAD_LIST,
    SET_REPRICE_DATA,
    SET_SEARCH,
    SET_TAB_NB,
    SET_TOTAL_PAID,
    SET_TRAVELER_TO_REMOVE,
    SET_TRIP_DOCS,
    SET_TRIP_DOCS_DOC,
    SET_TRIP_EXTERNAL_CODE,
    SET_TRIP_INFO,
    SET_TRIP_INFO_VERSION,
    SET_TRIP_LIST,
    SET_TRIP_LIST_FILTER,
    SET_TRIP_STATUS_FILTER,
    SET_TRIP_TOKEN,
    SET_USE_FARE_BASIS,
    TOGGLE_OPEN_CONFIRM_CANCEL,
    TOGGLE_OPEN_FLIGHT_DETAILS,
    TOGGLE_OPEN_REMOVE_TRAVELER,
    UPDATE_END_DATE,
    UPDATE_GROUPS,
    UPDATE_PGI,
    UPDATE_START_DATE,
    UPDATE_TRAVELERS,
} from './actionTypes';
import { ListDateType } from '../objects/dateType';
import { ReservationMode } from '../../flight/objects/reservationMode';


const initialState: TripState = {
    agencies: [],
    agency_filter: null,
    agency_user_filter: [],
    agency_users: [],
    count: -1,
    creator_filter: [],
    creators: [],
    date_type: ListDateType.current_month,
    deposit_amount: 0,
    end_date: null,
    files_to_dl: [],
    filtered_date: "",
    filtered_end_date: moment().format("YYYY-MM-DD"),
    filtered_start_date: moment().format("YYYY-MM-DD"),
    flight: null,
    insurances: [],
    groups: [],
    group_to_replace: [],
    launch_edit: false,
    launch_option: false,
    limit: 50,
    margin_reduction_percentage: 0,
    min_deposit: 0,
    mode_after_option: ReservationMode.Deposit,
    open_confirm_cancel: false,
    open_flight_details: false,
    open_remove_traveler: false,
    open_reprice_result: false,
    order: 'asc',
    order_by: '-id',
    page: 0,
    pax_number: [],
    pgi: '',
    possible_fares: [],
    reload_list: false,
    reprice_data: null,
    search: '',
    start_date: null,
    tab_nb: 0,
    total_paid: 0,
    travelers: [],
    traveler_to_remove: null,
    trip_docs: [],
    trip_external_code: "",
    trip_info: null,
    trip_list: null,
    trip_list_filter: "",
    trip_status_filter: [],
    trip_token: "",
    use_fare_basis: false,
    user_list: []
};

export default (state = initialState, action: TripActionTypes): TripState => {
    switch (action.type) {
        case ADD_FILE_TO_DL: {
            const file = Object.assign({}, action.payload);
            let new_list = state.files_to_dl;
            new_list.push(file);
            return {
                ...state,
                files_to_dl: new_list
            }
        }
        case CHANGE_GROUP_TO_REPLACE: {
            let group_to_add = action.payload;
            let new_groups = state.group_to_replace.slice();

            if (new_groups.some(item => item === group_to_add)){
                let group_to_remove_index = new_groups.findIndex(item => item === group_to_add);
                new_groups.splice(group_to_remove_index, 1);
            }else{
                new_groups.push(group_to_add);
            }

            return {
                ...state,
                group_to_replace: new_groups
            }
        }
        case CHOOSE_MODE_AFTER_OPTION: {
            return {
                ...state,
                mode_after_option: action.payload
            }
        }
        case CLEAR_FILES_TO_DL: {
            return {
                ...state,
                files_to_dl: []
            }
        }
        case RESET_FILTERS: {
            return {
                ...state,
                agencies: [],
                agency_filter: null,
                agency_users: [],
                agency_user_filter: [],
                creators: [],
                creator_filter: [],
                date_type: ListDateType.current_month,
                filtered_date: "",
                filtered_end_date: moment().format("YYYY-MM-DD"),
                filtered_start_date: moment().format("YYYY-MM-DD"),
                pax_number: [],
                trip_status_filter: [],
                trip_list_filter: ""
            }
        }
        case RESET_TRIP: {
            return {
                ...state,
                pgi: '',
                start_date: null,
                end_date: null,
                travelers: [],
                groups: []
            };
        }
        case SET_AGENCIES: {
            return {
                ...state,
                agencies: action.payload
            }
        }
        case SET_AGENCY_FILTER: {
            return {
                ...state,
                agency_filter: action.payload
            }
        }
        case SET_AGENCY_USER_FILTER: {
            return {
                ...state,
                agency_user_filter: action.payload
            }
        }
        case SET_AGENCY_USERS: {
            return {
                ...state,
                agency_users: action.payload
            }
        }
        case SET_CREATOR_FILTER: {
            return {
                ...state,
                creator_filter: action.payload
            }
        }
        case SET_CREATORS: {
            return {
                ...state,
                creators: action.payload
            }
        }
        case SET_DATE_TYPE: {
            return {
                ...state,
                date_type: action.payload
            }
        }
        case SET_DEPOSIT_AMOUNT: {
            return {
                ...state,
                deposit_amount: action.payload
            }
        }
        case SET_FILTERED_DATE: {
            return {
                ...state,
                filtered_date: action.payload
            }
        }
        case SET_FILTERED_END_DATE: {
            return {
                ...state,
                filtered_end_date: action.payload
            }
        }
        case SET_FILTERED_START_DATE: {
            return {
                ...state,
                filtered_start_date: action.payload
            }
        }
        case SET_FLIGHT: {
            return {
                ...state,
                flight: action.payload
            }
        }
        case SET_FLIGHT_GROUP_PASSENGER: {
            let group_passenger = action.payload;
            let copy_flight = Object.assign({}, state.flight);
            copy_flight.group_passenger = group_passenger;
            return {
                ...state,
                flight: copy_flight
            }
        }
        case SET_FLIGHT_TRANSLATED: {
            return {
                ...state,
                flight_translated: action.payload
            }
        }
        case SET_GROUP_TO_REPLACE: {
            return {
                ...state,
                group_to_replace: action.payload
            }
        }
        case SET_INSURANCES: {
            return {
                ...state,
                insurances: action.payload
            }
        }
        case SET_LAUNCH_EDIT: {
            return {
                ...state,
                launch_edit: action.payload
            }
        }
        case SET_LAUNCH_OPTION: {
            return {
                ...state,
                launch_option: action.payload
            }
        }
        case SET_LIMIT: {
            return {
                ...state,
                limit: action.payload
            };
        }
        case SET_MARGIN_REDUCTION_PERCENTAGE: {
            return {
                ...state,
                margin_reduction_percentage: action.payload
            }
        }
        case SET_MIN_DEPOSIT: {
            return {
                ...state,
                min_deposit: action.payload
            }
        }
        case SET_OPEN_REPRICE_RESULT: {
            return {
                ...state,
                open_reprice_result: action.payload
            }
        }
        case SET_ORDER: {
            return {
                ...state,
                order: action.payload
            };
        }
        case SET_ORDER_BY: {
            return {
                ...state,
                order_by: action.payload
            };
        }
        case SET_PAGE: {
            return {
                ...state,
                page: action.payload
            };
        }
        case SET_PAX_NUMBER: {
            return {
                ...state,
                pax_number: action.payload
            }
        }
        case SET_RELOAD_LIST: {
            return {
                ...state,
                reload_list: action.payload
            }
        }
        case SET_REPRICE_DATA: {
            return {
                ...state,
                reprice_data: action.payload
            }
        }
        case SET_SEARCH: {
            return {
                ...state,
                search: action.payload
            };
        }
        case SET_TAB_NB: {
            return {
                ...state,
                tab_nb: action.payload
            }
        }
        case SET_TOTAL_PAID: {
            return {
                ...state,
                total_paid: action.payload
            }
        }
        case SET_TRAVELER_TO_REMOVE: {
            return {
                ...state,
                traveler_to_remove: action.payload
            }
        }
        case SET_TRIP_DOCS: {
            return {
                ...state,
                trip_docs: action.payload
            }
        }
        case SET_TRIP_DOCS_DOC: {
            let new_document = action.payload;
            let copy_docs = state.trip_docs.slice();
            let new_document_index = copy_docs.findIndex(element => element.id === new_document.id);
            if (new_document_index !== -1){
                copy_docs[new_document_index] = new_document;
            }
            return {
                ...state,
                trip_docs: copy_docs
            }
        }
        case SET_TRIP_EXTERNAL_CODE: {
            return {
                ...state,
                trip_external_code: action.payload
            }
        }
        case SET_TRIP_INFO: {
            let trip_info = action.payload;
            if (trip_info !== null && trip_info.current_version_details === undefined) {
                trip_info.data.map(version => {
                    if (version.id === trip_info.current_version) {
                        trip_info.current_version_details = version;
                        if (version.for_user !== null){
                            trip_info.applicant = `${version.for_user.last_name !== null && version.for_user.last_name.toUpperCase()} ${version.for_user.first_name !== null && version.for_user.first_name}`
                        }
                    }
                });
            }
            return {
                ...state,
                trip_info: trip_info,
                start_date: trip_info !== null && trip_info.current_version_details !== undefined ? trip_info.current_version_details.start_date : null,
                end_date: trip_info !== null && trip_info.current_version_details !== undefined ? trip_info.current_version_details.end_date : null
            }
        }
        case SET_TRIP_INFO_VERSION: {
            if (state.trip_info !== null){
                let version = action.payload;
                let copy_trip_info = Object.assign({}, state.trip_info);
                let version_index = state.trip_info.data.findIndex(element => element.id === version.id);
                copy_trip_info.data[version_index] = version;

                if (version.id === copy_trip_info.current_version){
                    copy_trip_info.current_version_details = version;
                }
                return {
                    ...state,
                    trip_info: copy_trip_info
                }
            }else{
                return{
                    ...state
                }
            }
        }
        case SET_TRIP_LIST: {
            const tripResults = action.payload;
            if (tripResults !== null){
                tripResults.results.map((trip) => {
                    let versions = [] as TripVersion[];
                    trip.data.map((version) => {
                        if (version.id === trip.current_version) {
                            trip.current_version_details = version;
                            if (version.for_user !== null){
                                trip.applicant = `${version.for_user.last_name !== null && version.for_user.last_name.toUpperCase()} ${version.for_user.first_name !== null && version.for_user.first_name}`
                            }
                        } else {
                            versions.push(version);
                        }
                    });
                    trip.versions = versions;
                });
                return {
                    ...state,
                    count: tripResults.count,
                    trip_list: tripResults.results
                };
            }else{
                return {
                    ...state,
                    count: state.count,
                    trip_list: null
                }
            }
        }
        case SET_TRIP_LIST_FILTER: {
            return {
                ...state,
                trip_list_filter: action.payload
            }
        }
        case SET_TRIP_STATUS_FILTER: {
            return {
                ...state,
                trip_status_filter: action.payload
            }
        }
        case SET_TRIP_TOKEN: {
            return {
                ...state,
                trip_token: action.payload
            }
        }
        case SET_USE_FARE_BASIS: {
            return {
                ...state,
                use_fare_basis: action.payload
            }
        }
        case TOGGLE_OPEN_CONFIRM_CANCEL: {
            return {
                ...state,
                open_confirm_cancel: action.payload
            }
        }
        case TOGGLE_OPEN_FLIGHT_DETAILS: {
            return {
                ...state,
                open_flight_details: !state.open_flight_details
            }
        }
        case TOGGLE_OPEN_REMOVE_TRAVELER: {
            return {
                ...state,
                open_remove_traveler: !state.open_remove_traveler
            }
        }
        case UPDATE_END_DATE: {
            return {
                ...state,
                end_date: action.payload
            };
        }
        case UPDATE_GROUPS: {
            return {
                ...state,
                groups: action.payload
            };
        }
        case UPDATE_PGI: {
            return {
                ...state,
                pgi: action.payload
            };
        }
        case UPDATE_START_DATE: {
            return {
                ...state,
                start_date: action.payload
            };
        }
        case UPDATE_TRAVELERS: {
            return {
                ...state,
                travelers: action.payload
            };
        }
        default:
            return state;
    }
}