export enum rateType {
    Percentage = "PER",
    Value = "VAL"
}

export type Price = {
    created_date?: string,
    custom_rate: boolean,
    custom_rate_type: rateType,
    custom_value?: string,
    factor_used?: string,
    id?: number,
    is_commissioned?: boolean,
    is_network: boolean,
    is_surcom: boolean,
    master_price: boolean,
    modified_date?: string,
    owner: number,
    percentage_between?: string,
    purchase_currency: number,
    purchase_price: string,
    selling_currency: number,
    selling_price: string
}