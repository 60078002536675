import { Alliance } from "../objects/alliance";
import { Airline } from "../objects/airline";
import { Airport, AirportsObj, AirportReduced, AirportType } from "../objects/airport";
import { BillingInfoMode } from "../objects/billingInfoMode";
import { CheckedLuggage } from "../objects/checkedLuggage";
import { Company } from "../objects/company";
import { FilterTime } from "../objects/filterTime";
import { Flight } from "../objects/flight";
import { FlightGroup } from "../objects/flightGroup";
import { Insurance, SelectedInsurance } from "../objects/insurance";
import { Journey } from "../objects/journey";
import { LuggageOption } from "../objects/luggageOption";
import { MatrixData } from "../objects/matrixData";
import { MatrixFlight } from "../objects/matrixFlight";
import { NewFare } from "../objects/newFare";
import { OptionsResult } from "../objects/optionsResult";
import { OutrightMode } from "../objects/outrightMode";
import { PaymentRemark } from "../objects/paymentRemark";
import { PaymentSchedule } from "../objects/paymentSchedule";
import { PriceVariation } from "../objects/priceVariation";
import { ProductPaxPrice } from "../objects/pax_price";
import { Provider } from "../objects/provider";
import { ReservationMode } from "../objects/reservationMode";
import { SeatsBySegment } from "../objects/seatsBySegment";
import { SelectedSeat } from "../objects/selectedSeat";
import { StationsObj } from "../objects/station";
import { Stopover } from "../objects/stopover";
import { StopoverDuration } from "../objects/stopoverDuration";
import { StopoverActive } from "../objects/stopoverActive";
import { Traveler } from "../../travelers/objects/traveler";

export const ADD_BAGGAGE_OPTION = "ADD_BAGGAGE_OPTION";
export const ADD_FETCH_DATA = "ADD_FETCH_DATA";
export const ADD_FLIGHT_NEW_FARES = "ADD_FLIGHT_NEW_FARES";
export const ADD_HOVER_TO_MATRIX = "ADD_HOVER_TO_MATRIX";
export const ADD_JOURNEY_TO_FLIGHT = "ADD_JOURNEY_TO_FLIGHT";
export const ADD_PAYMENT_REMARK = "ADD_PAYMENT_REMARK"
export const ADD_SELECTED_PRICE = "ADD_SELECTED_PRICE";
export const ADD_TRAVELER = "ADD_TRAVELER";
export const APPLY_REPRICE_FLIGHT = "APPLY_REPRICE_FLIGHT";
export const CHANGE_FLIGHT_GROUPS_MODE = "CHANGE_FLIGHT_GROUPS_MODE";
export const CHANGE_MATRIX_LUGGAGE = "CHANGE_MATRIX_LUGGAGE";
export const CHANGE_OUTRIGHT_MODE = "CHANGE_OUTRIGHT_MODE";
export const CHANGE_PAY_REMARK_DESCRIPTION = "CHANGE_PAY_REMARK_DESCRIPTION";
export const CHANGE_PAY_REMARK_TYPE = "CHANGE_PAY_REMARK_TYPE";
export const CHANGE_PAY_REMARK_VALUE = "CHANGE_PAY_REMARK_VALUE";
export const CHANGE_RESERVATION_MODE = "CHANGE_RESERVATION_MODE";
export const CLEAR_FILTERS = "CLEAR_FILTERS"
export const CREATE_FLIGHT_HOURS = "CREATE_FLIGHT_HOURS";
export const CREATE_STOP_OVER_DURATION = "CREATE_STOP_OVER_DURATION";
export const DELETE_FLIGHT_GROUP_AT_INDEX = "DELETE_FLIGHT_GROUP_AT_INDEX";
export const DISPLAY_LIST = "DISPLAY_LIST";
export const EDIT_TRAVELER = "EDIT_TRAVELER";
export const EMPTY_INSURANCES = "EMPTY_INSURANCES";
export const EMPTY_LUGGAGE = "EMPTY_LUGGAGE";
export const EMPTY_PAYMENT_REMARKS = "EMPTY_PAYMENT_REMARKS";
export const EMPTY_SEATS = "EMPTY_SEATS";
export const GET_PROVIDERS = "GET_PROVIDERS";
export const IMPORT_PRODUCT_PARAMS = "IMPORT_PRODUCT_PARAMS";
export const LOAD_SAVED_STATE = "LOAD_SAVED_STATE";
export const OPEN_PRICE_VARIATION = "OPEN_PRICE_VARIATION";
export const REMOVE_BAGGAGE_OPTION = "REMOVE_BAGGAGE_OPTION";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const REMOVE_PASSENGER_BAGGAGE = "REMOVE_PASSENGER_BAGGAGE";
export const REMOVE_PNR_REMARK = "REMOVE_PNR_REMARK";
export const REMOVE_SELECTED_INSURANCE = "REMOVE_SELECTED_INSURANCE";
export const REMOVE_SELECTED_SEAT = "REMOVE_SELECTED_SEAT";
export const REMOVE_SELECTED_PRICE = "REMOVE_SELECTED_PRICE";
export const REMOVE_TRAVELER = "REMOVE_TRAVELER";
export const REPLACE_GROUP = "REPLACE_GROUP";
export const RESET_STATE = "RESET_STATE";
export const RESET_ERROR = "RESET_ERROR";
export const RESET_FETCH_ERROR = "RESET_FETCH_ERROR";
export const RESET_MATRIX_HOVER = "RESET_MATRIX_HOVER";
export const RESET_SELECTED_PRICE = "RESET_SELECTED_PRICE";
export const REVERT_JOURNEYS = "REVERT_JOURNEYS";
export const SAVE_FLIGHT_GROUPS = "SAVE_FLIGHT_GROUPS";
export const SELECT_AIRLINES = "SELECT_AIRLINES";
export const SELECT_FLIGHT = "SELECT_FLIGHT";
export const SELECT_INSURANCE = "SELECT_INSURANCE";
export const SELECT_SEAT = "SELECT_SEAT";
export const SET_ACCEPT_CANCELLATION = "SET_ACCEPT_CANCELLATION";
export const SET_ACCEPT_CONFIRMATION = "SET_ACCEPT_CONFIRMATION";
export const SET_AIRPORT_JOURNEY = "SET_AIRPORT_JOURNEY";
export const SET_AIRPORT_JOURNEY_MULTI_DEST = "SET_AIRPORT_JOURNEY_MULTI_DEST";
export const SET_AIRPORT_JOURNEY_ROUNDTRIP = "SET_AIRPORT_JOURNEY_ROUNDTRIP";
export const SET_BILLING_INFO_MODE = "SET_BILLING_INFO_MODE";
export const SET_BUYER = "SET_BUYER";
export const SET_CHECKED_LUGGAGE = "SET_CHECKED_LUGGAGE";
export const SET_DEFAULT_AIRPORTS = "SET_DEFAULT_AIRPORTS";
export const SET_DIRECT_FLIGHT = "SET_DIRECT_FLIGHT";
export const SET_DURATION_FILTER = "SET_DURATION_FILTER";
export const SET_DURATION_SLIDER = "SET_DURATION_SLIDER";
export const SET_ERROR = "SET_ERROR";
export const SET_FEES_REMARK = "SET_FEES_REMARK";
export const SET_FETCH_DATA = "SET_FETCH_DATA";
export const SET_FETCH_DATA_STATUS = "SET_FETCH_DATA_STATUS";
export const SET_FETCH_ERROR = "SET_FETCH_ERROR";
export const SET_FETCH_MATRIX_STATUS = "SET_FETCH_MATRIX_STATUS"
export const SET_FLEXIBLE_DATE = "SET_FLEXIBLE_DATE";
export const SET_FLIGHT_HOURS = "SET_FLIGHT_HOURS";
export const SET_FLIGHT_HOURS_SLIDE = "SET_FLIGHT_HOURS_SLIDE";
export const SET_FLIGHT_LIST = "SET_FLIGHT_LIST";
export const SET_GET_OPTIONS = "SET_GET_OPTIONS";
export const SET_GROUP_INDEX = "SET_GROUP_INDEX";
export const SET_INCLUDED_LUGGAGE = "SET_INCLUDED_LUGGAGE";
export const SET_INITIAL_DEPOSIT = "SET_INITIAL_DEPOSIT";
export const SET_INSURANCE_CHOICES = "SET_INSURANCE_CHOICES";
export const SET_JOURNEY = "SET_JOURNEY";
export const SET_JOURNEY_TYPE = "SET_JOURNEY_TYPE";
export const SET_MANUAL_INFO = "SET_MANUAL_INFO";
export const SET_MATRIX_DATA = "SET_MATRIX_DATA";
export const SET_MATRIX_SELECTED_DAY = "SET_MATRIX_SELECTED_DAY";
export const SET_MIN_DEPOSIT = "SET_MIN_DEPOSIT";
export const SET_MULTIDEST_START_DATE = "SET_MULTIDEST_START_DATE";
export const SET_MULTIDEST_CLASS = "SET_MULTIDEST_CLASS";
export const SET_NEW_ENCRYPT_DATA = "SET_NEW_ENCRYPT_DATA";
export const SET_ONEWAY_CLASS = "SET_ONEWAY_CLASS";
export const SET_ONEWAY_JOURNEY = "SET_ONEWAY_JOURNEY";
export const SET_ONEWAY_ORIGIN_DATE = "SET_ONEWAY_ORIGIN_DATE";
export const SET_OPEN_ADD_LUGGAGE = "SET_OPEN_ADD_LUGGAGE";
export const SET_OPEN_CHANGE_MODE = "SET_OPEN_CHANGE_MODE";
export const SET_OPEN_CONFIRM_FLIGHT = "SET_OPEN_CONFIRM_FLIGHT";
export const SET_OPEN_GIR_REMINDER = "SET_GIR_REMINDER"
export const SET_OPTION_LOADED = "SET_OPTION_LOADED";
export const SET_OPTION_MODE_ONLY = "SET_OPTION_MODE_ONLY";
export const SET_OPTIONS_ERROR = "SET_OPTIONS_ERROR";
export const SET_PARAMS_SEARCH = "SET_PARAMS_SEARCH";
export const SET_PAYBOX_PROVIDER = "SET_PAYBOX_PROVIDER";
export const SET_PAYMENT_SCHEDULES = "SET_PAYMENT_SCHEDULES";
export const SET_PLEDG_PROVIDER = "SET_PLEDG_PROVIDER";
export const SET_PNR_NUMBER = "SET_PNR_NUMBER";
export const SET_PRESENCE_PROVIDER = "SET_PRESENCE_PROVIDER";
export const SET_PRICE_FILTER = "SET_PRICE_FILTER";
export const SET_PRICE_SLIDER = "SET_PRICE_SLIDER";
export const SET_PRICE_VARIATION = "SET_PRICE_VARIATION";
export const SET_PRIVATE_PRICE = "SET_PRIVATE_PRICE";
export const SET_PUBLIC_PRICE = "SET_PUBLIC_PRICE";
export const SET_PVT_REMARK = "SET_PVT_REMARK";
export const SET_REMOVE_BILLING_ID = "SET_REMOVE_BILLING_ID"
export const SET_REMOVE_INSURANCE = "SET_REMOVE_INSURANCE";
export const SET_REMOVE_LUGGAGE = "SET_REMOVE_LUGGAGE";
export const SET_REMOVE_SEAT = "SET_REMOVE_SEAT";
export const SET_ROUNDTRIP_CLASS = "SET_ROUNDTRIP_CLASS";
export const SET_ROUNDTRIP_JOURNEY = "SET_ROUNDTRIP_JOURNEY";
export const SET_ROUNDTRIP_ORIGIN_DATE = "SET_ROUNDTRIP_ORIGIN_DATE";
export const SET_ROUNDTRIP_RETURN_DATE = "SET_ROUNDTRIP_RETURN_DATE";
export const SET_SEAT_CURRENT_SEGMENT = "SET_SEAT_CURRENT_SEGMENT";
export const SET_SEATS_BY_SEGMENT = "SET_SEATS_BY_SEGMENT";
export const SET_START_DATE_FILTER = "SET_START_DATE_FILTER";
export const SHOW_ALL_AIRLINES = "SHOW_ALL_AIRLINES";
export const SHOW_ALL_AIRPORTS = "SHOW_ALL_AIRPORTS";
export const SHOW_ALL_STOP_OVER_AIRPORTS = "SHOW_ALL_STOP_OVER_AIRPORTS";
export const SHOW_ALL_STATIONS = "SHOW_ALL_STATIONS";
export const SUB_JOURNEY_TO_FLIGHT = "SUB_JOURNEY_TO_FLIGHT";
export const SWAP_MULTIDEST_JOURNEY = "SWAP_MULTIDEST_JOURNEY";
export const TOGGLE_ACTIVE_STOPOVER_FILTER = "TOGGLE_ACTIVE_STOP_OVER_FILTER";
export const TOGGLE_ADD_LOYALTY_CARD = "TOGGLE_ADD_LOYALTY_CARD";
export const TOGGLE_ALL_STOP_OVER_DURATION = "TOGGLE_ALL_STOP_OVER_DURATION";
export const TOGGLE_ALLIANCE = "TOGGLE_ALLIANCE";
export const TOGGLE_ALLOTMENT_PRICE = "TOGGLE_ALLOTMENT_PRICE";
export const TOGGLE_AIRLINE = "TOGGLE_AIRLINE";
export const TOGGLE_AIRPORT = "TOGGLE_AIRPORT";
export const TOGGLE_BACK_TO_LIST = "TOGGLE_BACK_TO_LIST";
export const TOGGLE_DAY_FLIGHT = "TOGGLE_DAY_FLIGHT";
export const TOGGLE_DETAIL = "TOGGLE_DETAIL";
export const TOGGLE_NIGHT_FLIGHT = "TOGGLE_NIGHT_FLIGHT";
export const TOGGLE_OPEN_SKIP_OPTION = "TOGGLE_OPEN_SKIP_OPTION";
export const TOGGLE_PRIVATE_PRICE = "TOGGLE_PRIVATE_PRICE";
export const TOGGLE_PROVIDER = "TOGGLE_PROVIDER";
export const TOGGLE_PUBLIC_PRICE = "TOGGLE_PUBLIC_PRICE";
export const TOGGLE_SHOW_ALL_STOP_OVER = "TOGGLE_SHOW_ALL_STOP_OVER";
export const TOGGLE_STATION = "TOGGLE_STATION";
export const TOGGLE_STOP_OVER_AIRPORT = "TOGGLE_STOP_OVER_AIRPORT";
export const TOGGLE_STOP_OVER_DURATION = "TOGGLE_STOP_OVER_DURATION";
export const TOGGLE_SYNCHRONIZED_STOP_OVER = "TOGGLE_SYNCHRONIZED_STOP_OVER";
export const TOGGLE_WITH_APPLICANT = "TOGGLE_WITH_APPLICANT";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_FLIGHT_LIST = "UPDATE_FLIGHT_LIST";
export const UPDATE_RETRIEVE_PNR = "UPDATE_RETRIEVE_PNR";

interface AddBaggageOption {
    type: typeof ADD_BAGGAGE_OPTION,
    payload: {
        option_key: string,
        options: Array<LuggageOption>,
        selected_flight: number,
        outbound_index: number,
        segment_key: string,
        traveler_id: number,
        option_index: number
    }
}

interface AddFetchData {
    type: typeof ADD_FETCH_DATA,
    payload: {
        alliances: Array<Alliance> | null,
        airlines: Array<Airline> | null,
        airports: AirportsObj | null,
        flight_list: Array<Flight>,
        max_duration: number | null,
        max_price: number | null,
        max_start_date: string | null,
        min_duration: number | null,
        min_price: number | null,
        min_start_date: string | null,
        stations: StationsObj | null,
        stop_over: Stopover | null,
        stop_over_airport: Array<Record<string | number, Airport>> | null
    }
}

interface AddFlightNewFares {
    type: typeof ADD_FLIGHT_NEW_FARES,
    payload: {
        flight_index: number,
        group_to_replace: Array<number>,
        new_fares: Array<NewFare>,
        original_flight: Flight
    }
}

interface AddHoverToMatrix {
    type: typeof ADD_HOVER_TO_MATRIX,
    payload: { index_start: number, index_return: number | undefined }
}

interface AddJourneyToFlight {
    type: typeof ADD_JOURNEY_TO_FLIGHT,
    payload: { group_index: number, journey: Journey }
}

interface AddPaymentRemark {
    type: typeof ADD_PAYMENT_REMARK,
    payload: PaymentRemark
}


interface AddSelectedPrice {
    type: typeof ADD_SELECTED_PRICE,
    payload: number
}

interface AddTraveler {
    type: typeof ADD_TRAVELER,
    payload: { group_index: number, traveler: Traveler }
}

interface ApplyRepriceFlight {
    type: typeof APPLY_REPRICE_FLIGHT,
    payload: {
        currency_id: number,
        margin_reduction_percentage: number,
        new_ltd: string,
        new_pax_prices: Array<ProductPaxPrice>,
        pax_object: {
            ADT: number,
            CNN: number,
            INF: number
        },
        price_variation: number,
        taxes_variation: number,
        total_margin: number
    }
}

interface ChangeFlightGroupsMode {
    type: typeof CHANGE_FLIGHT_GROUPS_MODE,
    payload: "search" | "gir" | 'retrieve'
}

interface ChangeMatrixLuggage {
    type: typeof CHANGE_MATRIX_LUGGAGE,
    payload: string
}

interface ChangeOutrightMode {
    type: typeof CHANGE_OUTRIGHT_MODE,
    payload: OutrightMode
}

interface ChangePayRemarkDescription {
    type: typeof CHANGE_PAY_REMARK_DESCRIPTION,
    payload: { remark_index: number, new_description: string | null }
}

interface ChangePayRemarkType {
    type: typeof CHANGE_PAY_REMARK_TYPE,
    payload: { remark_index: number, new_type: string }
}

interface ChangePayRemarkValue {
    type: typeof CHANGE_PAY_REMARK_VALUE,
    payload: { remark_index: number, value: string | number }
}

interface ChangeReservationMode {
    type: typeof CHANGE_RESERVATION_MODE,
    payload: ReservationMode
}

interface ClearFilters {
    type: typeof CLEAR_FILTERS,
    payload: Array<FilterTime>
}

interface CreateFlightHours {
    type: typeof CREATE_FLIGHT_HOURS,
    payload: number
}

interface CreateStopoverDuration {
    type: typeof CREATE_STOP_OVER_DURATION,
    payload: number
}

interface DeleteFlightGroupAtIndex {
    type: typeof DELETE_FLIGHT_GROUP_AT_INDEX,
    payload: number
}

interface DisplayList {
    type: typeof DISPLAY_LIST,
    payload: Array<Flight>
}

interface EditTraveler {
    type: typeof EDIT_TRAVELER,
    payload: { group_index: number, traveler: Traveler}
}

interface EmptyInsurances {
    type: typeof EMPTY_INSURANCES,
    payload: null
}

interface EmptyLuggage {
    type: typeof EMPTY_LUGGAGE,
    payload: null
}

interface EmptyPaymentRemarks {
    type: typeof EMPTY_PAYMENT_REMARKS,
    payload: null
}

interface EmptySeats {
    type: typeof EMPTY_SEATS,
    payload: null
}

interface GetProviders {
    type: typeof GET_PROVIDERS,
    payload: { group_index: number, provider: {[key: string]: any} }
}

interface ImportProductParams {
    type: typeof IMPORT_PRODUCT_PARAMS,
    payload: {
        airlines: Array<Airline> | null,
        airports: AirportsObj | null,
        flight_groups: Array<FlightGroup>,
        flight_list: Array<Flight>,
        stations: StationsObj | null,
        trace_id: string | null
    }
}

interface LoadSavedState {
    type: typeof LOAD_SAVED_STATE,
    payload: {
        accept_cancellation: boolean,
        accept_confirmation: boolean,
        alliances: Array<Alliance> | null,
        airlines: Array<Airline> | null,
        airports: AirportsObj | null,
        billing_info_mode: string,
        buyer: Traveler | null,
        currency: string,
        currency_symbol: string,
        fees_remark: number,
        flight_groups: Array<FlightGroup>,
        flight_list: Array<Flight>,
        index_group: number,
        initial_deposit: number,
        lowest_price: number | null,
        lowest_price_one: number | null,
        lowest_price_two: number | null,
        lowest_price_zero: number | null,
        matrix_data: Array<MatrixData> | null,
        matrix_flight: Array<MatrixFlight> | null,
        matrix_flight_one: Array<MatrixFlight> | null,
        matrix_flight_two: Array<MatrixFlight> | null,
        matrix_flight_zero: Array<MatrixFlight> | null,
        matrix_length: number | null,
        matrix_luggage_nb: string,
        matrix_outbound_date: Array<string> | null,
        matrix_return_date: Array<string> | null,
        option_loaded: boolean,
        paybox_provider: number | null,
        pledg_provider: number | null,
        pnr_number: string | null,
        presence_provider: number | null,
        payment_remarks: Array<Record<string, string | number>>,
        reservation_mode: string,
        outright_mode: string | null,
        selected_flight: number | null,
        selected_insurances: Record<number, SelectedInsurance>,
        selected_price: number,
        selected_seats: Array<Array<SelectedSeat>>,
        stations: StationsObj | null,
        trace_id: string | null,
        with_applicant: boolean
    }
}

interface OpenPriceVariation {
    type: typeof OPEN_PRICE_VARIATION,
    payload: boolean
}

interface RemoveBagageOption {
    type: typeof REMOVE_BAGGAGE_OPTION,
    payload: {
        baggage_index: number,
        outbound_index: number,
        segment_key: string,
        traveler_id: number
    }
}

interface RemoveError {
    type: typeof REMOVE_ERROR,
    payload: { group_index: number, error: string }
}

interface RemovePassengerBaggage {
    type: typeof REMOVE_PASSENGER_BAGGAGE,
    payload: {
        selected_flight: number,
        outbound_index: number,
        segment_key: string,
        traveler_id: number
    }
}

interface RemovePnrRemark {
    type: typeof REMOVE_PNR_REMARK,
    payload: number
}

interface RemoveSelectedInsurance {
    type: typeof REMOVE_SELECTED_INSURANCE,
    payload: { traveler_id: number }
}

interface RemoveSelectedSeat {
    type: typeof REMOVE_SELECTED_SEAT,
    payload: { segment_index: number, traveler_id: number }
}

interface RemoveSelectedPrice {
    type: typeof REMOVE_SELECTED_PRICE,
    payload: number
}

interface RemoveTraveler {
    type: typeof REMOVE_TRAVELER,
    payload: { group_index: number, pax_type: string }
}

interface ReplaceGroup {
    type: typeof REPLACE_GROUP,
    payload: { group_index: number, new_group: FlightGroup }
}

interface ResetState {
    type: typeof RESET_STATE,
    payload: null
}

interface ResetError {
    type: typeof RESET_ERROR,
    payload: number
}

interface ResetFetchError {
    type: typeof RESET_FETCH_ERROR,
    payload: null
}

interface ResetMatrixHover {
    type: typeof RESET_MATRIX_HOVER,
    payload: null
}

interface ResetSelectedPrice {
    type: typeof RESET_SELECTED_PRICE,
    payload: null
}

interface RevertJourneys {
    type: typeof REVERT_JOURNEYS,
    payload: number
}

interface SaveFlightGroups {
    type: typeof SAVE_FLIGHT_GROUPS,
    payload: null
}

interface SelectAirlines {
    type: typeof SELECT_AIRLINES,
    payload: { group_index: number, airlines: Array<Company>}
}

interface SelectFlight {
    type: typeof SELECT_FLIGHT,
    payload: number | null
}

interface SelectInsurance {
    type: typeof SELECT_INSURANCE,
    payload: {
        traveler_id: number,
        insurance: SelectedInsurance
    }
}

interface SelectSeat {
    type: typeof SELECT_SEAT,
    payload: {
        segment_index: number,
        traveler_id: number,
        traveler_name: string,
        seat_number: string,
        price?: string
    }
}

interface SetAcceptCancellation {
    type: typeof SET_ACCEPT_CANCELLATION,
    payload: boolean
}

interface SetAcceptConfirmation {
    type: typeof SET_ACCEPT_CONFIRMATION,
    payload: boolean
}

interface SetAirportJourney {
    type: typeof SET_AIRPORT_JOURNEY,
    payload: { group_index: number, journey_index: number, type: AirportType, airport: null | AirportReduced }
}

interface SetAirportJourneyMultiDest {
    type: typeof SET_AIRPORT_JOURNEY_MULTI_DEST,
    payload: { group_index: number, journey_index: number, type: AirportType, airport: null | AirportReduced }
}


interface SetAirportJourneyRoundtrip {
    type: typeof SET_AIRPORT_JOURNEY_ROUNDTRIP,
    payload: { group_index: number, journey_index: number, type: AirportType, airport: null | AirportReduced }
}

interface SetBillingInfoMode {
    type: typeof SET_BILLING_INFO_MODE,
    payload: BillingInfoMode
}

interface SetBuyer {
    type: typeof SET_BUYER,
    payload: Traveler | null
}

interface SetCheckedLuggage {
    type: typeof SET_CHECKED_LUGGAGE,
    payload: string
}

interface SetDefaultAirports {
    type: typeof SET_DEFAULT_AIRPORTS,
    payload: Array<AirportReduced>
}

interface SetDirectFlight {
    type: typeof SET_DIRECT_FLIGHT,
    payload: { group_index: number, direct_flight: boolean, one_stop: boolean,  }
}

interface SetDurationFilter {
    type: typeof SET_DURATION_FILTER,
    payload: {
        min_duration: number,
        max_duration: number
    }
}

interface SetDurationSlider {
    type: typeof SET_DURATION_SLIDER,
    payload: {
        min_duration: number,
        max_duration: number
    }
}

interface SetError {
    type: typeof SET_ERROR,
    payload : { group_index: number, error: string }
}

interface SetFeesRemark {
    type: typeof SET_FEES_REMARK,
    payload: number
}

interface SetFetchData {
    type: typeof SET_FETCH_DATA,
    payload: {
        alliances: Array<Alliance> | null,
        airlines: Array<Airline> | null,
        airports: AirportsObj | null,
        currency: string,
        currency_symbol: undefined | string,
        distance_unit: string | null,
        filter_time: Array<FilterTime> | null,
        flight_list: Array<Flight>,
        max_duration: number | null,
        max_price: number | null,
        max_start_date: string | null,
        min_duration: number | null,
        min_price: number | null,
        min_start_date: string | null,
        stations: StationsObj | null,
        stop_over: Stopover | null,
        stop_over_airport: Array<Record<string | number, Airport>> | null,
        stop_over_duration: Array<StopoverDuration> | null,
        trace_id: string | null
    }
}

interface SetFetchDataStatus {
    type: typeof SET_FETCH_DATA_STATUS,
    payload: string | null
}

interface SetFetchError {
    type: typeof SET_FETCH_ERROR,
    payload: string
}

interface SetFetchedMatrixStatus {
    type: typeof SET_FETCH_MATRIX_STATUS,
    payload: string | null
}

interface SetFlexibleDate {
    type: typeof SET_FLEXIBLE_DATE,
    payload: { group_index: number, flexible_date: boolean }
}

interface SetFlightHours {
    type: typeof SET_FLIGHT_HOURS,
    payload: { outbound_index: number, departure_or_arrival: string, start_hour: number, end_hour: number }
}

interface SetFlightHoursSlide {
    type: typeof SET_FLIGHT_HOURS_SLIDE,
    payload: { outbound_index: number, departure_or_arrival: string, start_hour: number, end_hour: number }
}

interface SetFlightList {
    type: typeof SET_FLIGHT_LIST,
    payload: Array<Flight>
}

interface SetGetOptions {
    type: typeof SET_GET_OPTIONS,
    payload: { flight_index: number, options: OptionsResult }
}

interface SetGroupIndex {
    type: typeof SET_GROUP_INDEX,
    payload: number
}

interface SetIncludedLuggage {
    type: typeof SET_INCLUDED_LUGGAGE,
    payload: { group_index: number, included_luggage: boolean }
}

interface SetInitialDeposit {
    type: typeof SET_INITIAL_DEPOSIT,
    payload: number
}

interface SetInsuranceChoices {
    type: typeof SET_INSURANCE_CHOICES,
    payload: Array<Insurance>
}

interface SetJourney {
    type: typeof SET_JOURNEY,
    payload: { group_index: number, journey: Journey }
}

interface SetJourneyType {
    type: typeof SET_JOURNEY_TYPE,
    payload: { group_index: number, journey_type: number }
}

interface SetManualInfo {
    type: typeof SET_MANUAL_INFO,
    payload: {
        airlines: Array<Airline> | null,
        airports: AirportsObj | null,
        flight_list: Array<Flight>,
        trace_id: string | null,
        last_ticketing: string | null
    }
}

interface SetMatrixData {
    type: typeof SET_MATRIX_DATA;
    payload: {
        lowest_price: number | null,
        lowest_price_one: number | null,
        lowest_price_two: number | null,
        lowest_price_zero: number | null,
        matrix_data: Array<MatrixData> | null,
        matrix_flight: Array<MatrixFlight> | null,
        matrix_flight_one: Array<MatrixFlight> | null,
        matrix_flight_two: Array<MatrixFlight> | null,
        matrix_flight_zero: Array<MatrixFlight> | null,
        matrix_length: number | null,
        matrix_luggage_nb: string,
        matrix_outbound_date: Array<string> | null,
        matrix_return_date: Array<string> | null,
    }
}

interface SetMatrixSelectedDay {
    type: typeof SET_MATRIX_SELECTED_DAY,
    payload: {
        index_start: number,
        index_return: number | undefined
    }
}

interface SetMinDeposit {
    type: typeof SET_MIN_DEPOSIT,
    payload: number
}

interface SetMultiDestClass {
    type: typeof SET_MULTIDEST_CLASS,
    payload: { group_index: number, cabin_class: number, journey_index: number}
}

interface SetNewEncryptData {
    type: typeof SET_NEW_ENCRYPT_DATA,
    payload: string
}

interface SetMultiDestStartDate {
    type: typeof SET_MULTIDEST_START_DATE,
    payload: { group_index: number, date: string, journey_index: number}
}

interface SetOnewayClass {
    type: typeof SET_ONEWAY_CLASS,
    payload: { group_index: number, cabin_class: number }
}

interface SetOnewayJourney {
    type: typeof SET_ONEWAY_JOURNEY,
    payload: number
}

interface SetOnewayOriginDate {
    type: typeof SET_ONEWAY_ORIGIN_DATE,
    payload: { group_index: number, date: string }
}

interface SetOpenAddLuggage {
    type: typeof SET_OPEN_ADD_LUGGAGE,
    payload: boolean
}

interface SetOpenChangeMode {
    type: typeof SET_OPEN_CHANGE_MODE,
    payload: boolean
}

interface SetOpenConfirmFlight {
    type: typeof SET_OPEN_CONFIRM_FLIGHT,
    payload: boolean
}


interface SetOpenGirReminder {
    type: typeof SET_OPEN_GIR_REMINDER,
    payload: boolean
}


interface SetOptionsError {
    type: typeof SET_OPTIONS_ERROR,
    payload: { flight_index: number, error: string }
}

interface SetOptionLoaded {
    type: typeof SET_OPTION_LOADED,
    payload: boolean
}

interface SetOptionModeOnly {
    type: typeof SET_OPTION_MODE_ONLY,
    payload: boolean
}

interface SetParamsSearch {
    type: typeof SET_PARAMS_SEARCH,
    payload: string
}

interface SetPayboxProvider {
    type: typeof SET_PAYBOX_PROVIDER,
    payload: number | null
}

interface SetPaymentSchedules {
    type: typeof SET_PAYMENT_SCHEDULES,
    payload: null | Record<string, PaymentSchedule>
}

interface SetPlegdProvider {
    type: typeof SET_PLEDG_PROVIDER,
    payload: number | null
}

interface SetPnrNumber {
    type: typeof SET_PNR_NUMBER,
    payload: string
}

interface SetPresenceProvider {
    type: typeof SET_PRESENCE_PROVIDER,
    payload: number | null
}

interface SetPriceFilter {
    type: typeof SET_PRICE_FILTER,
    payload: { min_price: number, max_price: number }
}

interface SetPriceSlider {
    type: typeof SET_PRICE_SLIDER,
    payload: { min_price: number, max_price: number }
}

interface SetPriceVariation {
    type: typeof SET_PRICE_VARIATION,
    payload: PriceVariation
}

interface SetPrivatePrice {
    type: typeof SET_PRIVATE_PRICE,
    payload: { group_index: number, private_price: boolean }
}

interface SetPublicPrice {
    type: typeof SET_PUBLIC_PRICE,
    payload: { group_index: number, public_price: boolean }
}

interface SetPvtRemark {
    type: typeof SET_PVT_REMARK,
    payload: string
}

interface SetRoundtripClass {
    type: typeof SET_ROUNDTRIP_CLASS,
    payload: { group_index: number, cabin_class: number }
}

interface SetRoundtripJourney {
    type: typeof SET_ROUNDTRIP_JOURNEY,
    payload: number
}

interface SetRoundtripOriginDate {
    type: typeof SET_ROUNDTRIP_ORIGIN_DATE,
    payload: { group_index: number, date: string}
}

interface SetRoundtripReturnDate {
    type: typeof SET_ROUNDTRIP_RETURN_DATE,
    payload: { group_index: number, date: string}
}

interface SetSeatCurrentSegment {
    type: typeof SET_SEAT_CURRENT_SEGMENT,
    payload: number
}

interface SetSeatsBySegment {
    type: typeof SET_SEATS_BY_SEGMENT,
    payload: Array<SeatsBySegment>
}

interface SetStartDateFilter {
    type: typeof SET_START_DATE_FILTER,
    payload: { min_date: string, max_date: string }
}

interface ShowAllAirlines {
    type: typeof SHOW_ALL_AIRLINES,
    payload: null
}

interface ShowAllAirports {
    type: typeof SHOW_ALL_AIRPORTS,
    payload: null
}

interface ShowAllStations {
    type: typeof SHOW_ALL_STATIONS,
    payload: null
}

interface ShowAllStopoverAirports {
    type: typeof SHOW_ALL_STOP_OVER_AIRPORTS,
    payload: null
}

interface SubJourneyToFlight {
    type: typeof SUB_JOURNEY_TO_FLIGHT,
    payload: { group_index: number, journey_index: number}
}

interface SwapMultidestJourney {
    type: typeof SWAP_MULTIDEST_JOURNEY,
    payload: { group_index: number, old_index: number, new_index: number }
}


interface ToggleActiveStopoverFilter {
    type: typeof TOGGLE_ACTIVE_STOPOVER_FILTER,
    payload: string
}

interface ToggleAddLoyaltyCard {
    type: typeof TOGGLE_ADD_LOYALTY_CARD,
    payload: null
}

interface ToggleAlliance {
    type: typeof TOGGLE_ALLIANCE,
    payload: number
}

interface ToggleAllotmentPrice {
    type: typeof TOGGLE_ALLOTMENT_PRICE,
    payload: null
}

interface ToggleAirline {
    type: typeof TOGGLE_AIRLINE,
    payload: number
}

interface ToggleAirport {
    type: typeof TOGGLE_AIRPORT,
    payload: number
}

interface ToggleAllStopoverDuration {
    type: typeof TOGGLE_ALL_STOP_OVER_DURATION,
    payload: null
}

interface ToggleBackToList {
    type: typeof TOGGLE_BACK_TO_LIST,
    payload: null
}

interface ToggleDayFlight {
    type: typeof TOGGLE_DAY_FLIGHT,
    payload: null
}

interface ToggleDetail {
    type: typeof TOGGLE_DETAIL,
    payload: { flight_index: number, outbound_index: number, detail: boolean }
}

interface ToggleNightFlight {
    type: typeof TOGGLE_NIGHT_FLIGHT,
    payload: null
}

interface SetRemoveBillingId {
    type: typeof SET_REMOVE_BILLING_ID,
    payload: number
}

interface SetRemoveInsurance {
    type: typeof SET_REMOVE_INSURANCE,
    payload: { open_remove_insurance: boolean, traveler_id: number | null }
}

interface SetRemoveLuggage {
    type: typeof SET_REMOVE_LUGGAGE,
    payload: { open_remove_luggage: boolean, baggage_index: number | null, outbound_index: number | null, segment_key: string | null, traveler_id: number | null }
}

interface SetRemoveSeat {
    type: typeof SET_REMOVE_SEAT,
    payload: { open_remove_seat: boolean, outbound_index: number | null, traveler_id: number | null }
}

interface ToggleOpenSkipOption {
    type: typeof TOGGLE_OPEN_SKIP_OPTION,
    payload: null
}

interface TogglePrivatePrice {
    type: typeof TOGGLE_PRIVATE_PRICE,
    payload: null
}

interface ToggleProvider{
    type: typeof TOGGLE_PROVIDER,
    payload: number
}

interface TogglePublicPrice {
    type: typeof TOGGLE_PUBLIC_PRICE,
    payload: null
}

interface ToggleShowAllStopover {
    type: typeof TOGGLE_SHOW_ALL_STOP_OVER,
    payload: null
}

interface ToggleStation {
    type: typeof TOGGLE_STATION,
    payload: number
}

interface ToggleStopoverAirport {
    type: typeof TOGGLE_STOP_OVER_AIRPORT,
    payload: { journey_index: number, airport_id: number }
}

interface ToggleStopoverDuration {
    type: typeof TOGGLE_STOP_OVER_DURATION,
    payload: { outbound_index: number, stop_over_key: string }
}

interface ToggleSynchronizedStopover {
    type: typeof TOGGLE_SYNCHRONIZED_STOP_OVER,
    payload: null
}

interface ToggleWithApplicant {
    type: typeof TOGGLE_WITH_APPLICANT,
    payload: null
}

interface UpdateFlightList {
    type: typeof UPDATE_FLIGHT_LIST,
    payload: { flight_list_render: Array<Flight>, sort_value: number }
}

interface UpdateRetrievePnr {
    type: typeof UPDATE_RETRIEVE_PNR,
    payload: string
}

interface UpdateFilters {
    type: typeof UPDATE_FILTERS,
    payload: {
        airlines: Array<Airline> | null,
        airports: AirportsObj | null,
        alliances: Array<Alliance> | null,
        checked_luggage: CheckedLuggage,
        is_day_flight: boolean | null,
        is_night_flight: boolean | null,
        private_price: boolean | null,
        providers: Array<Provider>,
        public_price: boolean | null,
        show_all_stop_over_duration: boolean | null,
        stations: StationsObj | null,
        stop_over_active: StopoverActive,
        stop_over_airport: Array<Record<string | number, Airport>> | null
    }
}

export type FlightActionTypes = (
    AddBaggageOption | AddFetchData | AddFlightNewFares | AddHoverToMatrix | AddJourneyToFlight | AddPaymentRemark | AddSelectedPrice | AddTraveler | ApplyRepriceFlight
    | ChangeFlightGroupsMode | ChangeMatrixLuggage | ChangeOutrightMode | ChangePayRemarkDescription | ChangePayRemarkType | ChangePayRemarkValue | ChangeReservationMode
    | ClearFilters | CreateFlightHours | CreateStopoverDuration | DeleteFlightGroupAtIndex | DisplayList | EditTraveler
    | EmptyInsurances | EmptyLuggage | EmptyPaymentRemarks | EmptySeats | GetProviders | ImportProductParams | LoadSavedState | OpenPriceVariation
    | RemoveBagageOption | RemoveError | RemovePassengerBaggage | RemovePnrRemark | RemoveSelectedInsurance | RemoveSelectedSeat | RemoveSelectedPrice | RemoveTraveler | ReplaceGroup
    | ResetState | ResetError | ResetFetchError | ResetMatrixHover | ResetSelectedPrice  | RevertJourneys | SaveFlightGroups
    | SelectAirlines | SelectFlight | SelectInsurance | SelectSeat  | SetAcceptCancellation | SetAcceptConfirmation | SetAirportJourney | SetAirportJourneyMultiDest
    | SetAirportJourneyRoundtrip | SetBillingInfoMode | SetBuyer | SetCheckedLuggage | SetDefaultAirports | SetDirectFlight | SetDurationFilter | SetDurationSlider | SetError
    | SetFeesRemark | SetFetchData | SetFetchDataStatus | SetFetchError
    | SetFetchedMatrixStatus | SetFlexibleDate | SetFlightHours | SetFlightHoursSlide | SetFlightList | SetGetOptions | SetGroupIndex | SetIncludedLuggage | SetInitialDeposit
    | SetInsuranceChoices | SetJourney | SetJourneyType | SetManualInfo | SetMatrixData | SetMatrixSelectedDay | SetMinDeposit | SetMultiDestClass | SetMultiDestStartDate | SetNewEncryptData
    | SetOnewayClass | SetOnewayJourney | SetOnewayOriginDate | SetOpenAddLuggage | SetOpenChangeMode | SetOpenConfirmFlight | SetOpenGirReminder | SetOptionLoaded | SetOptionModeOnly | SetOptionsError
    | SetParamsSearch | SetPayboxProvider | SetPaymentSchedules | SetPlegdProvider | SetPnrNumber | SetPresenceProvider | SetPriceFilter | SetPriceSlider
    | SetPriceVariation | SetPrivatePrice | SetPublicPrice | SetPvtRemark | SetRemoveBillingId
    | SetRemoveInsurance | SetRemoveLuggage | SetRemoveSeat | SetRoundtripClass | SetRoundtripJourney | SetRoundtripOriginDate | SetRoundtripReturnDate
    | SetSeatCurrentSegment | SetSeatsBySegment | SetStartDateFilter | ShowAllAirlines | ShowAllAirports | ShowAllStations | ShowAllStopoverAirports
    | SubJourneyToFlight | SwapMultidestJourney | ToggleActiveStopoverFilter | ToggleAddLoyaltyCard
    | ToggleAirline | ToggleAirport | ToggleAlliance | ToggleAllotmentPrice | ToggleBackToList | ToggleAllStopoverDuration
    | ToggleDayFlight | ToggleDetail | ToggleNightFlight | ToggleOpenSkipOption | TogglePrivatePrice | ToggleProvider | TogglePublicPrice | ToggleShowAllStopover
    | ToggleStopoverAirport | ToggleStopoverDuration | ToggleStation | ToggleSynchronizedStopover | ToggleWithApplicant
    | UpdateFilters | UpdateFlightList | UpdateRetrievePnr
);
