// Dependencies
import React, { FC, ReactElement } from 'react';
// Material UI Components
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    fullHeight: {
        minHeight: '100vh'
    }
});

const Loading: FC = (): ReactElement => {
    const classes = useStyles();
    return (
        <Grid className={ classes.fullHeight } container justify={ 'center' } alignItems={ 'center' }>
            <Grid item>
                <CircularProgress size={ 100 }/>
            </Grid>
        </Grid>
    )
};

export default Loading;