import { User } from '../../user/objects/user';
import { CHECK_EMAIL_DONE, LOAD_PROFILE_STATE, SET_CUSTOMER_PROFILE, SET_PROFILE, SET_LOGIN, ProfileActionTypes } from './actionTypes';

export const checkEmailDone = (): ProfileActionTypes => ({
    type: CHECK_EMAIL_DONE,
    payload: null
})

export const loadProfileState = (customer_profile: User): ProfileActionTypes => ({
    type: LOAD_PROFILE_STATE,
    payload: {
        customer_profile: customer_profile
    }
})

export const setCustomerProfile = (profile: null | User): ProfileActionTypes => ({
    type: SET_CUSTOMER_PROFILE,
    payload: profile
})

export const setProfile = (profile: null | User): ProfileActionTypes => ({
    type: SET_PROFILE,
    payload: profile
});

export const setLogin = (token: string, user: User | null, permissions: []): ProfileActionTypes => ({
    type: SET_LOGIN,
    payload: {
        token: token,
        user: user,
        permissions: permissions
    }
});