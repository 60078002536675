import { FinishedState } from '../objects/finishedState';
import { FinishedActionTypes, SET_FINISHED } from './actionTypes';

const initialState: FinishedState = {
    finished: false
};

export default (state: FinishedState = initialState, action: FinishedActionTypes): FinishedState => {
    switch (action.type) {
        case SET_FINISHED: {
            return {...state, finished: action.payload};
        }
        default:
            return state;
    }
};