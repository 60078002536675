const initialState = {
	timer: null,
	timer_open: false,
	availability_open: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case "DECREASE_TIMER": {
			if (state.timer !== null && state.timer > 0) {
				return {...state, timer: state.timer - 1};
			}
			return state;
		}
		case "SET_TIMER": {
			return {...state, timer: action.payload};
		}
		case "SET_TIMER_AVAILABILITY": {
			return {...state, availability_open: action.payload};
		}
		case "SET_TIMER_OPEN": {
			if (action.payload === true) {
				return {...state, timer_open: action.payload, timer: 1200};
			}
			return {...state, timer_open: action.payload};
		}
		default:
			return state;
	}
};