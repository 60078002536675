import { Errors } from '../objects/errors';

export const UPDATE_EMAIL = 'LOGIN_UPDATE_EMAIL';
export const UPDATE_PASSWORD = 'LOGIN_UPDATE_PASSWORD';
export const TOGGLE_FORGOTTEN_PASSWORD = "LOGIN_TOGGLE_FORGOTTEN";
export const TOGGLE_OPEN_CONFIRMATION = "TOGGLE_OPEN_CONFIRMATION";
export const TOGGLE_PASSWORD = 'LOGIN_TOGGLE_PASSWORD';
export const SET_ERRORS = 'LOGIN_SET_ERRORS';

interface UpdateEmailAction {
    type: typeof UPDATE_EMAIL,
    payload: string
}

interface UpdatePasswordAction {
    type: typeof UPDATE_PASSWORD,
    payload: string
}

interface ToggleForgottenPassword {
    type: typeof TOGGLE_FORGOTTEN_PASSWORD,
    payload: null
}

interface ToggleOpenConfirmation {
    type: typeof TOGGLE_OPEN_CONFIRMATION,
    payload: null
}

interface ToggleShowPasswordAction {
    type: typeof TOGGLE_PASSWORD
}

interface SetErrorsAction {
    type: typeof SET_ERRORS,
    payload: Errors
}

export type LoginActionTypes = UpdateEmailAction | UpdatePasswordAction | ToggleForgottenPassword | ToggleOpenConfirmation | ToggleShowPasswordAction | SetErrorsAction;