import { combineReducers } from 'redux';
import login from './component/login/redux/reducer';
import header from './component/header/redux/reducer';
import loading from './component/utils/loading/redux/reducer';
import finished from './component/utils/finished/redux/reducer';
import flight from './component/flight/redux/reducer';
import profile from './component/profile/redux/reducer';
import reset_password from './component/resetPasswordBis/redux/reducer'
import traveler from './component/travelers/redux/reducer';
import trip from './component/trip/redux/reducer';
import user from './component/user/redux/reducer';
import timer from './component/timer/redux/reducer';

export const rootReducer = combineReducers({
    login,
    header,
    loading,
    finished,
    flight,
    profile,
    reset_password,
    traveler,
    trip,
    user,
    timer
});

export type AppState = ReturnType<typeof rootReducer>;