import { Flight } from "../../flight/objects/flight";
import { FlightProduct } from "../../flight/objects/flightProduct";
import { ReservationMode } from "../../flight/objects/reservationMode";
import { Traveler } from "../../travelers/objects/traveler";
import { TravelerGroup } from "../../travelers/objects/travelerGroup";
import { User } from "../../user/objects/user";
import { Agency } from "../objects/agency";
import { ListDateType } from "../objects/dateType";
import { Document } from "../objects/document";
import { InsuranceProduct } from "../objects/insuranceProduct";
import { RepriceData } from "../objects/repriceData";
import { Trip } from "../objects/trip";
import { TripResults } from "../objects/tripResults";
import { TripVersion } from "../objects/tripVersion";

export const ADD_FILE_TO_DL = "ADD_FILE_TO_DL";
export const CHANGE_GROUP_TO_REPLACE = "CHANGE_GROUP_TO_REPLACE"
export const CHOOSE_MODE_AFTER_OPTION = "CHOOSE_MODE_AFTER_OPTION";
export const CLEAR_FILES_TO_DL = "CLEAR_FILES_TO_DL";
export const RESET_FILTERS = "RESET_FILTERS";
export const RESET_TRIP = 'RESET_TRIP';
export const SET_AGENCIES = "SET_AGENCIES";
export const SET_AGENCY_FILTER = "SET_AGENCY_FILTER";
export const SET_AGENCY_USER_FILTER = "SET_AGENCY_USER_FILTER";
export const SET_AGENCY_USERS = "SET_AGENCY_USERS";
export const SET_CREATOR_FILTER = "SET_CREATOR_FILTER";
export const SET_CREATORS = "SET_CREATORS";
export const SET_DATE_TYPE = "SET_DATE_TYPE";
export const SET_DEPOSIT_AMOUNT = "SET_DEPOSIT_AMOUNT";
export const SET_FILTERED_DATE = "SET_FILTERED_DATE";
export const SET_FILTERED_END_DATE = "SET_FILTERED_END_DATE";
export const SET_FILTERED_START_DATE = "SET_FILTERED_START_DATE";
export const SET_FLIGHT = 'SET_FLIGHT';
export const SET_FLIGHT_TRANSLATED = 'SET_FLIGHT_TRANSLATED';
export const SET_FLIGHT_GROUP_PASSENGER = "SET_FLIGHT_GROUP_PASSENGER";
export const SET_GROUP_TO_REPLACE = "SET_GROUP_TO_REPLACE";
export const SET_INSURANCES = "SET_INSURANCES";
export const SET_LAUNCH_EDIT = "SET_LAUNCH_EDIT";
export const SET_LAUNCH_OPTION = "SET_LAUNCH_OPTION";
export const SET_LIMIT = 'SET_LIMIT';
export const SET_MARGIN_REDUCTION_PERCENTAGE = "SET_MARGIN_REDUCTION_PERCENTAGE";
export const SET_MIN_DEPOSIT = "SET_MIN_DEPOSIT";
export const SET_OPEN_REPRICE_RESULT = "SET_OPEN_REPRICE_RESULT";
export const SET_ORDER = 'SET_ORDER';
export const SET_ORDER_BY = 'SET_ORDER_BY';
export const SET_PAGE = 'SET_PAGE';
export const SET_PAX_NUMBER = "SET_PAX_NUMBER";
export const SET_RELOAD_LIST = "SET_RELOAD_LIST";
export const SET_REPRICE_DATA = "SET_REPRICE_DATA";
export const SET_SEARCH = 'SET_SEARCH';
export const SET_TAB_NB = "SET_TAB_NB";
export const SET_TOTAL_PAID = "SET_TOTAL_PAID";
export const SET_TRAVELER_TO_REMOVE = "SET_TRAVELER_TO_REMOVE";
export const SET_TRIP_DOCS = "SET_TRIP_DOCS";
export const SET_TRIP_DOCS_DOC = "SET_TRIP_DOCS_DOC";
export const SET_TRIP_EXTERNAL_CODE = "SET_TRIP_EXTERNAL_CODE";
export const SET_TRIP_INFO = "SET_TRIP_INFO";
export const SET_TRIP_INFO_VERSION = "SET_TRIP_INFO_VERSION";
export const SET_TRIP_LIST = 'SET_TRIP_LIST';
export const SET_TRIP_LIST_FILTER = "SET_TRIP_LIST_FILTER";
export const SET_TRIP_STATUS_FILTER = "SET_TRIP_STATUS_FILTER";
export const SET_TRIP_TOKEN = "SET_TRIP_TOKEN";
export const SET_USE_FARE_BASIS = "SET_USE_FARE_BASIS";
export const TOGGLE_OPEN_CONFIRM_CANCEL = "TOGGLE_OPEN_CONFIRM_CANCEL";
export const TOGGLE_OPEN_FLIGHT_DETAILS = "TOGGLE_OPEN_FLIGHT_DETAILS";
export const TOGGLE_OPEN_REMOVE_TRAVELER = "TOGGLE_OPEN_REMOVE_TRAVELER";
export const UPDATE_END_DATE = 'UPDATE_END_DATE';
export const UPDATE_GROUPS = 'UPDATE_GROUPS';
export const UPDATE_PGI = 'UPDATE_PGI';
export const UPDATE_START_DATE = 'UPDATE_START_DATE';
export const UPDATE_TRAVELERS = 'UPDATE_TRAVELERS';

interface AddFileToDl {
    type: typeof ADD_FILE_TO_DL,
    payload: {
        file_name: string,
        blob: Blob
    }
}

interface changeGroupToReplace {
    type: typeof CHANGE_GROUP_TO_REPLACE,
    payload: number
}

interface ChoooseModeAfterOption {
    type: typeof CHOOSE_MODE_AFTER_OPTION,
    payload: ReservationMode
}

interface ClearFilesToDl {
    type: typeof CLEAR_FILES_TO_DL,
    payload: null
}

interface ResetFilters {
    type: typeof RESET_FILTERS,
    payload: null
}

interface ResetTrip {
    type: typeof RESET_TRIP,
    payload: null
}

interface SetAgencies {
    type: typeof SET_AGENCIES,
    payload: Array<Agency>
}

interface SetAgencyFilter {
    type: typeof SET_AGENCY_FILTER,
    payload: Agency | null
}

interface SetAgencyUserFilter {
    type: typeof SET_AGENCY_USER_FILTER,
    payload: Array<User>
}

interface SetAgencyUsers {
    type: typeof SET_AGENCY_USERS,
    payload: Array<User>
}

interface SetCreatorFilter {
    type: typeof SET_CREATOR_FILTER,
    payload: Array<User>
}

interface SetCreators {
    type: typeof SET_CREATORS,
    payload: Array<User>
}

interface SetDateType {
    type: typeof SET_DATE_TYPE,
    payload: ListDateType
}

interface SetDepositAmount {
    type: typeof SET_DEPOSIT_AMOUNT,
    payload: number
}

interface SetFilteredDate {
    type: typeof SET_FILTERED_DATE,
    payload: string
}

interface SetFilteredEndDate {
    type: typeof SET_FILTERED_END_DATE,
    payload: string | null
}

interface SetFilteredStartDate {
    type: typeof SET_FILTERED_START_DATE,
    payload: string | null
}

interface SetFlight {
    type: typeof SET_FLIGHT,
    payload: FlightProduct | null
}

interface SetFlightGroupPassenger {
    type: typeof SET_FLIGHT_GROUP_PASSENGER,
    payload: TravelerGroup
}

interface SetFlightTranslated {
    type: typeof SET_FLIGHT_TRANSLATED,
    payload: Flight | null
}

interface SetGroupToReplace {
    type: typeof SET_GROUP_TO_REPLACE,
    payload: Array<number>
}

interface SetInsurances {
    type: typeof SET_INSURANCES,
    payload: Array<InsuranceProduct>
}

interface SetLaunchEdit {
    type: typeof SET_LAUNCH_EDIT,
    payload: boolean
}

interface SetLaunchOption {
    type: typeof SET_LAUNCH_OPTION,
    payload: boolean
}

interface SetLimit {
    type: typeof SET_LIMIT,
    payload: number
}

interface SetMinDeposit {
    type: typeof SET_MIN_DEPOSIT,
    payload: number
}

interface SetMarginReductionPercentage {
    type: typeof SET_MARGIN_REDUCTION_PERCENTAGE,
    payload: number
}

interface SetOpenRepriceResult {
    type: typeof SET_OPEN_REPRICE_RESULT,
    payload: boolean
}

interface SetOrder {
    type: typeof SET_ORDER,
    payload: string
}

interface SetOrderBy {
    type: typeof SET_ORDER_BY,
    payload: string
}

interface SetPage {
    type: typeof SET_PAGE,
    payload: number
}

interface SetPaxNumber {
    type: typeof SET_PAX_NUMBER,
    payload: Array<{label: string}>
}

interface SetReloadList {
    payload: boolean
    type: typeof SET_RELOAD_LIST,
}

interface SetRepriceData {
    payload: RepriceData | null,
    type: typeof SET_REPRICE_DATA
}

interface SetSearch {
    type: typeof SET_SEARCH,
    payload: string
}

interface SetTabNb {
    type: typeof SET_TAB_NB,
    payload: number
}

interface SetTravelerToRemove {
    type: typeof SET_TRAVELER_TO_REMOVE,
    payload: Traveler | null
}

interface SetTotalPaid {
    type: typeof SET_TOTAL_PAID,
    payload: number
}

interface SetTripDocs {
    type: typeof SET_TRIP_DOCS,
    payload: Array<Document>
}

interface SetTripDocsDoc {
    type: typeof SET_TRIP_DOCS_DOC,
    payload: Document
}

interface SetTripExternalCode {
    type: typeof SET_TRIP_EXTERNAL_CODE,
    payload: string
}

interface SetTripInfo {
    type: typeof SET_TRIP_INFO,
    payload: Trip | null
}

interface SetTripInfoVersion {
    type: typeof SET_TRIP_INFO_VERSION,
    payload: TripVersion
}

interface SetTripList {
    type: typeof SET_TRIP_LIST,
    payload: TripResults | null
}

interface SetTripListFilter {
    type: typeof SET_TRIP_LIST_FILTER,
    payload: string
}

interface SetTripToken {
    type: typeof SET_TRIP_TOKEN,
    payload: string
}

interface SetTripStatusFilter {
    type: typeof SET_TRIP_STATUS_FILTER,
    payload: Array<{label: string}>
}

interface SetUseFareBasis {
    type: typeof SET_USE_FARE_BASIS,
    payload: boolean
}

interface ToggleOpenConfirmCancel {
    type: typeof TOGGLE_OPEN_CONFIRM_CANCEL,
    payload: boolean
}

interface ToggleOpenFlightDetails {
    type: typeof TOGGLE_OPEN_FLIGHT_DETAILS,
    payload: null
}

interface ToggleOpenRemoveTraveler {
    type: typeof TOGGLE_OPEN_REMOVE_TRAVELER,
    payload: null
}

interface UpdateEndDate {
    type: typeof UPDATE_END_DATE,
    payload: string | null
}

interface UpdateGroups {
    type: typeof UPDATE_GROUPS,
    payload: Array<any>
}

interface UpdatePgi {
    type: typeof UPDATE_PGI,
    payload: string
}

interface UpdateStartDate {
    type: typeof UPDATE_START_DATE,
    payload: string | null
}

interface UpdateTravelers {
    type: typeof UPDATE_TRAVELERS,
    payload: Array<Traveler>
}

export type TripActionTypes = AddFileToDl | changeGroupToReplace | ChoooseModeAfterOption | ClearFilesToDl | ResetFilters | ResetTrip | SetAgencies | SetAgencyFilter | SetAgencyUserFilter | SetAgencyUsers
                            | SetCreatorFilter | SetCreators | SetDateType | SetDepositAmount | SetFilteredDate | SetFilteredEndDate | SetFilteredStartDate | SetFlight | SetFlightGroupPassenger | SetFlightTranslated
                            | SetGroupToReplace | SetInsurances | SetLaunchEdit | SetLaunchOption | SetLimit | SetMarginReductionPercentage | SetMinDeposit | SetOpenRepriceResult | SetOrder
                            | SetOrderBy | SetPage | SetPaxNumber | SetReloadList | SetRepriceData | SetSearch | SetTabNb | SetTotalPaid | SetTravelerToRemove
                            | SetTripDocs | SetTripDocsDoc | SetTripExternalCode | SetTripInfo | SetTripInfoVersion | SetTripList | SetTripListFilter | SetTripStatusFilter | SetTripToken
                            | SetUseFareBasis | ToggleOpenConfirmCancel | ToggleOpenFlightDetails | ToggleOpenRemoveTraveler
                            | UpdateEndDate | UpdateGroups | UpdatePgi | UpdateStartDate | UpdateTravelers