// Dependencies
import React, { FC, ReactElement } from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
// Core
import IconButton from '@material-ui/core/IconButton';
// Icons
import Close from '@material-ui/icons/Close';

const CloseSnackbar: FC<{key:SnackbarKey}> = ({ key }): ReactElement => {
    const { closeSnackbar } = useSnackbar();
    return (
        <IconButton onClick={() => closeSnackbar(key)}>
            <Close style={{color: 'white'}}/>
        </IconButton>
    );
};

export default CloseSnackbar;